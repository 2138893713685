import axios from 'axios';

const API_BASE_URL = 'https://rt5pmgczxepgaz3zclg2lssary0drgtr.lambda-url.us-west-1.on.aws/';
const API_BUDGET_URL = 'https://iektwg525ucvzm4pfzrlf6rney0fants.lambda-url.us-west-1.on.aws/';
const API_PROJECT_URL = 'https://1kh86gywll.execute-api.us-west-1.amazonaws.com/dev/';
//const API_PROJECT_URL = 'https://vm5jcfckninyt5urwihrtgy2ta0vzwuu.lambda-url.us-west-1.on.aws/';


export const userLogin = async (userName,password) => {


  try {
    const response = await axios.post(`${API_PROJECT_URL}login`, {
      user:userName,
      password:password
    }, {
      headers: {
        "Content-Type": "application/json", // Ensure JSON content type
      },
    });

    
    if (response.status === 200) {
      // Handle successful login, e.g., store token, redirect user
      console.log("Login exitoso:", response.data);
      return response;
    }
  } catch (error) {
    console.error("Error en el login :", error);
    
    return false;
  }
};

// Function to fetch users from the API
export const getUsers = async () => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}users`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener los usuarios: ", error);
    throw error;
  }
};

export const getUserPrivileges = async (userId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}users/${userId}/privileges`);
    return response.data; // Expected response: ["add_project", "edit_project", "delete_project"]
  } catch (error) {
    console.error("Error al obtener los privilegios del usuario: ", error);
    throw error;
  }
};

export const getPermissions = async () => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}permissions`);
    return response.data; // Expected response: ["add_project", "edit_project", "delete_project"]
  } catch (error) {
    console.error("Error al obtener los privilegios del usuario: ", error);
    throw error;
  }
};


export const getBudget = async (projectId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/budget`);
    console.log('Budget data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener presupuestos: ", error);
    throw error;
  }
};


export const getBudgetItems = async (projectId,budgetId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/budget/${budgetId}`);
    console.log('Budget details data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener presupuestos: ", error);
    throw error;
  }
};


export const getInquiries = async (projectId,budgetId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/inquiry`, { params: {budgetId: budgetId } });
    console.log('Inquiries data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener requisiciones: ", error);
    throw error;
  }
};




export const getApprovedInquiries = async (projectId,budgetId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/inquiry`, { params: {budgetId: budgetId ,filter:2} });
    console.log('Inquiries data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener requisiciones: ", error);
    throw error;
  }
};

export const deleteInquiry = async (projectId,inquiryId) => {
  try {
    const response = await axios.post(`${API_PROJECT_URL}projects/${projectId}/inquiry/${inquiryId}`);
    console.log('Inquiry delete response:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al borrar requisicion: ", error);
    throw error;
  }
};

export const approveInquiry = async (projectId,inquiryId, userId) => {
  try {
    const response = await axios.post(`${API_PROJECT_URL}projects/${projectId}/inquiry/${inquiryId}/approve`, {
      userId, // Pass the ID of the logged-in user
    });
    
    if (response.status === 202) {
      console.warn('Inquiry was already approved', response.data.message);
      throw new Error(
        response?.data?.message || 'Inquiry error'
      );
    }
    return response.data;
  } catch (error) {
    console.error('Error approving inquiry:', error.response?.data || error.message);
    throw new Error(error.response?.data?.message ||  error.message ||  'Failed to approve inquiry');
  }
};


export const approvePO = async (projectId,inquiryId, userId) => {
  try {
    const response = await axios.post(`${API_PROJECT_URL}projects/${projectId}/pos/${inquiryId}/approve`, {
      userId, // Pass the ID of the logged-in user
    });
    return response.data;
  } catch (error) {
    console.error('Error approving inquiry:', error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Failed to approve inquiry');
  }
};

export const updateInquiry = async (projectId,inquiryId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/inquiry/${inquiryId}`);
    console.log('Inquiry update response:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al actualizar requisicion: ", error);
    throw error;
  }
};

export const addInquiry = async (projectId, selectedBudgetId,userId, inquiryItems) => {
  try {
    const requestBody = {
      budgetId: selectedBudgetId,
      items: inquiryItems,
      userId : userId
    };

    const response = await axios.post(
      `${API_PROJECT_URL}projects/${projectId}/inquiry`,
      requestBody, // Add the request payload
      {
        headers: {
          "Content-Type": "application/json", // Ensure JSON content type
        },
      }
    );

    console.log("Inquiry add response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error al agregar requisicion: ", error);
    throw error;
  }
};

export const getUnits = async () => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}units`);
    return response.data; // Expected to return [{ id: 1, name: "kg" }, { id: 2, name: "m" }]
  } catch (error) {
    console.error("Error fetching units:", error);
    throw error;
  }
};



export const getQuoteItems = async (projectId,budgetId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/budget/${budgetId}`);
    console.log('Budget details data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener presupuestos: ", error);
    throw error;
  }
};
export const getProjects= async (queryData) => {
  try {
    const response = await axios.get(API_PROJECT_URL + 'projects',{params: queryData});
    console.log('Protected data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener proyectos: ", error);
    throw error;
  }
};

export const addProject = async (projectData) => {
  try {
    const response = await axios.post(`${API_PROJECT_URL}projects`, projectData);
    console.log('Protected data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener proyectos: ", error);
    throw error;
  }
};

export const updateProject = async (projectData) => {
  try {
    const response = await axios.put(`${API_PROJECT_URL}projects`, projectData);
    console.log('Protected data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al obtener proyectos: ", error);
    throw error;
  }
};

export const deleteProject= async (projectId,userId) => {
  try {
    const response = await axios.delete(`${API_PROJECT_URL}projects/${projectId}`, { data: {user_id: userId } });
    console.log('Protected data:', response.data);
    return response.data;
  } catch (error) {
    console.error("Error al borrar el proyecto: ", error);
    throw error;
  }
};

export const getQuote = async (projectId) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}projects/${projectId}/quotes`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener cotizaciones: ", error);
    throw error;
  }
};

export const getPOs = async (projectId, inquiryId) => {
  try {
    const response = await axios.get(
      `${API_PROJECT_URL}projects/${projectId}/pos`, 
      { params: { inquiryId } } // Use params for query string
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener órdenes de compra: ", error);
    throw error;
  }
};

export const addPO = async (projectId,poData) => {
  try {    
    const response = await axios.post(`${API_PROJECT_URL}projects/${projectId}/pos`, poData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data; // Example: { message: "Archivo importado con éxito" }
  } catch (error) {
    console.error("Error al importar el archivo CSV:", error);
    throw error;
  }
};


export const updatePO = async (poData) => {
  const response = await fetch(`/api/pos/${poData.po_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(poData),
  });
  return response.json();
};

export const deletePO = async (poId) => {
  await fetch(`/api/pos/${poId}`, { method: "DELETE" });
};

export const getNotifications = async (userId) => {
  const response = await axios.get(`${API_PROJECT_URL}notifications/${userId}`);
  return response.data;
};

// Mark a notification as read
export const markNotificationAsRead = async (params) => {
  try {
    const response = await axios.put(
      `${API_PROJECT_URL}notifications/mark-read`,
      null, // No request body is needed
      {
        params: params,
      }
    );
    return response.data; // Return the API response
  } catch (error) {
    console.error("Error al marcar la notificación como leída:", error);
    throw error;
  }
};

// Upload CSV file
export const uploadCsvFile = async (formData) => {
  try {    
    const response = await axios.post(`${API_PROJECT_URL}import-file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data; // Example: { message: "Archivo importado con éxito" }
  } catch (error) {
    console.error("Error al importar el archivo CSV:", error);
    throw error;
  }
};

export const getCatalog = async (type) => {
  const response = await axios.get(`${API_PROJECT_URL}catalog/${type}`);
  return response.data;
};

export const addCatalogItem = async (type, data) => {
  const response = await axios.post(`${API_BASE_URL}/catalogs/${type}`, data);
  return response.data;
};

export const updateCatalogItem = async (type, id, data) => {
  const response = await axios.put(`${API_BASE_URL}/catalogs/${type}/${id}`, data);
  return response.data;
};

export const deleteCatalogItem = async (type, id) => {
  const response = await axios.delete(`${API_BASE_URL}/catalogs/${type}/${id}`);
  return response.data;
};


export const getProjectStatuses = async (type) => {
  const response = await axios.get(`${API_PROJECT_URL}catalog/status`);
  return response.data;
};

export const getReportData = async (reportType, filters) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/reports/${reportType}`,
      { params: filters }
    );
    return response.data; // Expected array of report data
  } catch (error) {
    console.error("Error fetching report data:", error);
    throw error;
  }
};

// Fetch all configurations
export const getSystemConfigurations = async () => {
  const response = await axios.get(`${API_PROJECT_URL}config`);
  return response.data;
};


// Update an existing configuration
export const updateSystemConfiguration = async (updatedConfig) => {
  const response = await axios.put(`${API_PROJECT_URL}config`, updatedConfig);
  return response.data;
};

// Function to fetch users from the API
export const getVendors = async () => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}vendors`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener los usuarios: ", error);
    throw error;
  }
};


// Fetch all cashflow categories
export const getCategories = async () => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}cashflow_category`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener los usuarios: ", error);
    throw error;
  }
};

// Fetch all cashflows
export const getCashflows = async (queryParams = {}) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}cashflow`, {
      params: queryParams, // Pass query parameters dynamically
    });
    if ('monthlyBudget' in queryParams)
    {
      return {
        cashflows: response.data?.cashflow || [], // Ensure proper property access
        budget: response.data?.budget || [], // Ensure proper property access
      };

    }
    else{
      return response.data?.data || [];
    }
    
  } catch (error) {
    console.error("Error fetching cashflows:", error);
    throw error;
  }
};


// Add a new cashflow entry
export const addCashflow = async (cashflowData) => {
  const response = await axios.post(`${API_PROJECT_URL}cashflow`,     
  cashflowData);
  return {
        status: response.status, // HTTP status code
        data: response.data,     // Response body (dictionary)
      };
};

// Update an existing cashflow entry
export const updateCashflow = async (cashflowData) => {
  const response = await axios.put(
    `${API_PROJECT_URL}cashflow`,
    cashflowData
  );
  return response.data;
};

export const updatePettyCash = async (cashflowData) => {
  const response = await axios.put(
    `${API_PROJECT_URL}cashflow/petty_cash`,
    cashflowData
  );
  return response.data;
};


// Delete a cashflow entry
export const deleteCashflow = async (queryParams = {}) => {
  try{
    const response = await axios.delete(`${API_PROJECT_URL}cashflow`,{
      params: queryParams,});
    return  {
      status: response.status, // HTTP status code
      data: response.data,     // Response body (dictionary)
    }
  }
  catch (error) {
    console.error("Error al obtener los usuarios: ", error);
    throw error;
  }
};


export const deletePettyCash = async (queryParams = {}) => {
  try{
    const response = await axios.delete(`${API_PROJECT_URL}cashflow/petty_cash`,{
      params: queryParams,});
    return  {
      status: response.status, // HTTP status code
      data: response.data,     // Response body (dictionary)
    }
  }
  catch (error) {
    console.error("Error al obtener los usuarios: ", error);
    throw error;
  }
};


// Add a new cashflow entry
export const submitPettyCash = async (cashflowData) => {
  const response = await axios.post(`${API_PROJECT_URL}cashflow/petty_cash`,     
  cashflowData);
  return {
        status: response.status, // HTTP status code
        data: response.data,     // Response body (dictionary)
      };
};



export const getPettyCashContainers = async (queryParams = {}) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}cashflow`, {
      params: queryParams, // Pass query parameters dynamically
    });
    if ('monthlyBudget' in queryParams)
    {
      return {
        cashflows: response.data?.cashflow || [], // Ensure proper property access
        budget: response.data?.budget || [], // Ensure proper property access
      };

    }
    else{
      return response.data?.data || [];
    }
    
  } catch (error) {
    console.error("Error fetching cashflows:", error);
    throw error;
  }
};


// Fetch all cashflow categories
export const getBanks = async (queryParams) => {
  try {
    const response = await axios.get(`${API_PROJECT_URL}banks`)
    return response.data || [];
  } catch (error) {
    console.error("Error fetching cashflows:", error);
    throw error;
  }
};


export const getBankTransactions = async (bank_id,queryParams) => {
    try {
    const response = await axios.get(`${API_PROJECT_URL}banks/${bank_id}`, {
      params: queryParams, // Pass query parameters dynamically
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching cashflows:", error);
    throw error;
  }
};


export const getIncomingInvoices = async () => {
  const response = await axios.get(`${API_BASE_URL}cashflow_cateogry`);
  return response.data;
};


export const updateInvoiceStatus = async () => {
  const response = await axios.get(`${API_BASE_URL}cashflow_cateogry`);
  return response.data;
};

export const updateInvoiceDetails = async (cf_id,queryParams) => {
  const response = await axios.patch(`${API_PROJECT_URL}cashflow/${cf_id}`, {
    params: queryParams, // Pass query parameters dynamically
  });
  return response.data;
};


export const uploadBankStatement = async () => {
  const response = await axios.get(`${API_BASE_URL}cashflow_cateogry`);
  return response.data;
};

export const getSystemTransactions = async () => {
  const response = await axios.get(`${API_BASE_URL}cashflow_cateogry`);
  return response.data;
};

export const matchTransaction = async (bankid,queryParams) => {
  const response = await axios.get(`${API_PROJECT_URL}banks/${bankid}/matchTransaction`, {
    params: queryParams, // Pass query parameters dynamically
  });
  return  {
    status: response.status, // HTTP status code
    data: response.data,     // Response body (dictionary)
  }
};

export const conciliateTransaction = async (bankid,queryParams) => {
  const response = await axios.put(`${API_PROJECT_URL}banks/${bankid}/matchTransaction`, 
    queryParams // Pass query parameters dynamically
  );
  
  return  {
    status: response.status, // HTTP status code
    data: response.data,     // Response body (dictionary)
  }
};

// Example API to fetch pattern rules
export const getPatternRules = async () => {
  const response = await axios.get(`${API_PROJECT_URL}pattern-rules`);
  return response.data;
};

// Save or Update Pattern Rule
export const savePatternRule = async (rule) => {
  try{
    const response = await axios.post(`${API_PROJECT_URL}pattern-rules`, rule);
    return response.data;
  } catch (error) {
    console.error("Error fetching patterns:", error);
    throw error;
  }
};



