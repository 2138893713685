import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const updateScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add resize event listener
    window.addEventListener("resize", updateScreenSize);

    // Cleanup listener on unmount
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  return isMobile;
};

export default useIsMobile;
