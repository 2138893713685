export const hasAccess = (userPrivileges,rolePrivileges, requiredPrivilege, activeModules, moduleName) => {
  const accessPrivileges = [requiredPrivilege, requiredPrivilege.replace(":read", ":write")];

    // Check if the user has at least one of the required privileges
  const hasUserPrivilege = userPrivileges?.some(privilege => accessPrivileges.includes(privilege));
  const hasRolePrivilege = rolePrivileges?.some(privilege => accessPrivileges.includes(privilege));

  // Check if the module is active
  const hasModuleAccess = activeModules?.includes(moduleName);

  // Return true only if both conditions are satisfied
  return (hasUserPrivilege || hasRolePrivilege)  && hasModuleAccess;
};
