import React, { useEffect } from "react";
import { ListGroup, Badge, Button, Container } from "react-bootstrap";
import { useNotifications } from "../context/NotificationsContext";
import { markNotificationAsRead } from "../services/api";
import { Link } from "react-router-dom";

const NotificationsInbox = ({userId}) => {
  const { notifications, refetchNotifications } = useNotifications();


  const handleMarkAsRead = async (notificationId) => {
    await markNotificationAsRead({notificationId:notificationId,userId:userId});
    refetchNotifications(); // Refresh the notifications list after marking as read
  };

  return (
    <Container className="mt-4">
      <h2>Notificaciones</h2>
      {notifications.length === 0 ? (
        <p>No tienes notificaciones pendientes.</p>
      ) : (
        <ListGroup>
          {notifications.map((notification) => (
            <ListGroup.Item key={notification.notification_id}>
              <div
                id={notification.notification_id}
                className="d-flex justify-content-between align-items-center"
              >
                <span>{notification.message}</span>
                <span>
                  Link:{" "}
                  <Link
                    to={`/${notification.module_name}?budget_id=${notification.module_item_id}&project_id=${notification.project_id}`}
                    style={{ textDecoration: "underline", color: "blue" }}
                  >
                    Ir a {notification.module_name}
                  </Link>
                </span>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => handleMarkAsRead(notification.notification_id)}
                >
                  Marcar como leído
                </Button>
              </div>
              <Badge bg="secondary" className="mt-2">
                {new Date(notification.created_at).toLocaleString()}
              </Badge>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};

export default NotificationsInbox;
