import React, { useRef,useEffect, useState } from "react";
import { Table, InputGroup,Button, Form, Alert, Container , Row, Col } from "react-bootstrap";
import { useNotifications } from "../context/NotificationsContext";
import { statusTypeMap,operationTypeMap} from "../types/CashflowModuleTypes";
import { FaSearch } from "react-icons/fa";
import InvoiceSelectionModal from "../components/InvoiceSelectModal"; 

import {
  getProjects,
  getCategories,
  getCashflows,
  addCashflow,
  updateCashflow,
  deletePettyCash,
  getBanks,
  submitPettyCash,
  matchTransaction
} from "../services/api";

const CashflowPage = ({userId, userName,roleId}) => {
  const { refetchNotifications } = useNotifications();  
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newEntry,setNewEntry] = useState(true);
  const [banks, setBanks] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedFolio, setSelectedFolio] = useState(""); // Store the selected folio
  const [isTypingNewValue, setIsTypingNewValue] = useState(false);

  const [selectedVendorName, setSelectedVendorName] = useState(""); // Store the selected folio
  const [cashflows, setCashflows] = useState([]);
  const [cashflowList, setCashflowList] = useState([]);
  const [pettyCashContainer, setPettyCashContainer] = useState([]);
  const [pettyCashList, setPettyCashList] = useState([]);
  
  const [formattedAmount, setFormattedAmount] = useState("");
  const [editingCashflowId, setEditingCashflowId] = useState(null); // Track the cashflow being edited

  const formatDateToISO = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [newCashflow, setNewCashflow] = useState({
    cf_date: formatDateToISO(new Date()),
    project_id: "",
    category_id: "",
    concept: "",
    transaction_type: "cash",
    totalAmount: 0.00,
    name: "",
    operation_type: "expense",
    notes: "",
  });
  const [alert, setAlert] = useState(null);
  const [alertVariant, setAlertVariant] = useState('info');
  

  // Fetch Projects, Categories, and Cashflows on Load
  useEffect(() => {  
    const queryParams = {
      user_id: userId,      
      status:['pending', 'rejected']
    }; 
    const fetchData = async () => {      
      const queryParamsPettyCash = { user_id: userId, requestedBy:userId,petty_cash: 1, status: ["requested", "rejected"] };
      const [projectsData, categoriesData, banksData, cashflowsData, pettyCashData] = await Promise.all([
        getProjects(),
        getCategories(),
        getBanks(),
        getCashflows(queryParams),
        getCashflows(queryParamsPettyCash),
      ]);



      setProjects(projectsData);
      setCategories(categoriesData);
      setBanks(banksData);
     
      const transformedPettyCashData = (cashflowsData || []).map((entry) => ({
        ...entry,
        totalAmount: typeof entry.totalAmount === "string"
          ? parseFloat(entry.totalAmount.replace(/[^0-9.-]+/g, ""))
          : entry.totalAmount, // Ensure totalAmount is a number
      }));
  
      setCashflowList(transformedPettyCashData || []);
      setPettyCashList(pettyCashData);
    };
    fetchData();
  }, []);


  const handleAddToPettyCash = () => {
    if (!newCashflow.cf_date || !newCashflow.totalAmount || !newCashflow.project_id) {
      setSelectedFolio ("");
      setSelectedVendorName("");
      setAlert("Por favor, completa todos los campos obligatorios.");
      setAlertVariant("warning");
      return;
    }

    

    setPettyCashContainer((prev) => [...prev, { ...newCashflow ,totalAmount:normalizeAmount(newCashflow.totalAmount),currency_id:2}]);
    setNewCashflow({
      cf_date: formatDateToISO(new Date()),      
      category_id: "",
      project_id: newCashflow.project_id,
      concept: "",
      transaction_type: "cash",
      totalAmount: 0.00,
      name: "",
      operation_type: "expense",
      notes: "",
    });
    setNewEntry(false);
    setAlert("Entrada añadida a Caja Chica.");
    setAlertVariant("success");
  };


  const handleEditPettyCashEntry = (index) => {
    const entryToEdit = pettyCashContainer[index];
    setNewCashflow(entryToEdit);
    setNewEntry(false);
    setEditingCashflowId(index); // Track the index of the entry being edited
  };
  
  const handleUpdatePettyCashEntry = () => {
    if (editingCashflowId !== null) {
      setPettyCashContainer((prev) =>
        prev.map((entry, index) =>
          index === editingCashflowId ? { ...newCashflow } : entry
        )
      );
      setEditingCashflowId(null); // Clear the editing index
      setNewEntry(false);
      setNewCashflow({
        cf_date: formatDateToISO(new Date()),
        project_id: newCashflow.project_id,
        category_id: "",
        concept: "",
        transaction_type: "cash",
        totalAmount: 0.00,
        name: "",
        operation_type: "expense",
        notes: ""        
      });
      setAlert("Entrada de Caja Chica actualizada correctamente.");
      setAlertVariant("success");
    }
  };

  
  const handleSubmitPettyCash = async (totalAmount) => {
    if (pettyCashContainer.length === 0) {
      setAlert("No hay entradas en la Caja Chica para enviar.");
      setAlertVariant("warning");
      return;
    }

    try {
      const addedPettyCash = await submitPettyCash({ company_id: 1,notes: 'Nuevo registro de caja chica',requested_by: userId,project_id:newCashflow.project_id,user_id: userId, totalAmount: totalAmount,items: pettyCashContainer });

       // Add the new transaction to the state
       if(addedPettyCash.status == 201)
        {
            
          setPettyCashContainer([]);
          setEditingCashflowId(null); 
          refetchNotifications();
          setNewEntry(true);

          // Map `updatedCashflow` to the desired structure
          const sentPcRequest = {                        
            project_id: parseInt(newCashflow.project_id), // Static or fetched dynamically            
            reason: 'Nuevo registro de caja chica',            
            amount: totalAmount,   
            status:  "requested",     
            created_at:  Date.now(),
            request_id: addedPettyCash.data.item_id.petty_cash_id 
          };


          setPettyCashList((prev) => [
          { ...sentPcRequest|| Date.now() },
          ...prev,            
          ]);



          setNewCashflow({
            cf_date: formatDateToISO(new Date()), 
            project_id: "",     
            category_id: "",
            concept: "",
            transaction_type: "cash",
            totalAmount: 0.00,
            name: "",
            operation_type: "expense",
            notes: "",
          });
          setAlert("Caja Chica enviada para aprobación.");
          setAlertVariant("success");
        }
    } catch (error) {
      console.error("Error submitting petty cash:", error);
      setAlert("Error al enviar la Caja Chica.");
      setAlertVariant("danger");
    }
  };



  const handleSearchClick = async(filters) => {
  
    let queryParams;
    if (filters){           
       filters.source_type = filters.source_type || "bank";
       filters.searchType = filters.searchType || "name";    
       filters.company_id = 1;    
       filters.customSearch =  filters.nombre_emisor;
       
       
       // Filter out null, undefined, or empty string values
       const tempQueryParams = Object.fromEntries(
         Object.entries(filters).filter(([_, value]) => value !== null && value !== undefined && value !== "")
       );
   
      const queryParams = {...tempQueryParams,status:['pending']}
      try {
        // Fetch all necessary data in parallel
        setIsLoading(true);
        const missingInvoices = await matchTransaction(3, queryParams);


        if (missingInvoices.status === 200) {
          const fetchedInvoices = missingInvoices.data.data; // Assuming `data.data` contains the fetched invoices              
          setInvoices(fetchedInvoices);
          
          setShowModal(true); // S
        } 
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setAlert("Error al obtener transacciones.");
      }
    }
  };

  const handleInvoiceSelect = (selectedInvoice) => {
    setSelectedFolio(selectedInvoice.folio); // Store the selected folio    
    setNewCashflow((prev) => ({
      ...prev,
      invoice_id: selectedInvoice.uuid,
      totalAmount: selectedInvoice.amount,
      cf_date : selectedInvoice.fecha_emision
  }));
    setSelectedVendorName(selectedInvoice.name)
  };
  


  const handleEditCashflow = (cf_id) => {
    const cashflowToEdit = cashflowList.find((cf) => cf.cf_id === cf_id);
    if (cashflowToEdit) {
      setNewCashflow(cashflowToEdit);
      setEditingCashflowId(cf_id);
    }
  };


  const handleEditPettyCash = (request_id) => {
    const cashflowToEdit = pettyCashList.find((cf) => cf.request_id === request_id);
    if (cashflowToEdit) {
      setNewCashflow(cashflowToEdit);
      setEditingCashflowId(cashflowToEdit);
      const filteredCF = cashflowList.filter((item) => item.petty_cash_id === request_id) ;
      setPettyCashContainer(filteredCF);      
    }
  };

  
  const handleCancelEdit = () => {
    setEditingCashflowId(null);
    setNewCashflow({
      expense_date: formatDateToISO(new Date()),
      project_id: "",
      category_id: "",
      concept: "",
      transaction_type: "cash",
      totalAmount: 0.00,
      name: "",
      operation_type: "expense",
      notes: "",
    });
  };

  
  
  const handleFocus = (event) => {
    setIsTypingNewValue(true);
    event.target.select(); // Select the text content
  };


  const handleInputChange = async (e,field,index) => {
    const value = e.target.value;
    const input = e.target;
    const originalValue = input.value;
    const cursorPosition = input.selectionStart;

    if (field === "totalAmount"){
        // Allow only numbers and a single dot
        
        var formattedValue;
        var unformattedValue;

        if (isTypingNewValue) {
          setFormattedAmount(originalValue);
          setNewCashflow((prev) => ({
            ...prev,
            totalAmount: originalValue.replace(/[^0-9.-]/g, ""),
          }));
          return;
        }

        unformattedValue = originalValue.replace(/[^0-9.-]/g, "");
        formattedValue = parseFloat(unformattedValue)
          .toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace(/,/g, "");    
      
      
        const removedCommasBeforeCursor = originalValue
        .slice(0, cursorPosition)
        .split(",").length - 1;

        const offset =
        cursorPosition - removedCommasBeforeCursor + formattedValue.length - unformattedValue.length;

        setFormattedAmount(formattedValue);
        setNewCashflow((prev) => ({
            ...prev,
            totalAmount: parseFloat(value.replace(/[^0-9.-]+/g, "")),
        }));   
        setTimeout(() => {
          input.setSelectionRange(offset, offset);
        }, 0);       
    }
    else{  
        if (field === 'project_id')
        {
          const queryParams = {
            project_id: value,
            user_id: userId,
            status:['pending','rejected']
                             
          };
          const response =  await getCashflows(queryParams);
          if (response && Array.isArray(response)) {
            setCashflowList(response);
          }
           
        }
        setNewCashflow((prev) => ({
            ...prev,
            [field]: value, // Dynamically update the corresponding field
          }));
        //setNewCashflow({ ...newCashflow, [field]: e.target.value });
    }
 
  };

  // Format value when the input loses focus
  const handleBlur = () => {
    if (formattedAmount) {
      setFormattedAmount(null);
      setIsTypingNewValue(false); 
      setNewCashflow((prev) => ({
        ...prev,
        totalAmount: parseFloat(formattedAmount.replace(/[^0-9.-]+/g, "")),
    }));    
    }
  };


  const handleSaveCashflow = async (cf_id = null) => {
    try {
      if (editingCashflowId) {            
        // Update existing cashflow
        await updateCashflow({ ...newCashflow, cf_id: editingCashflowId , user_id:userId,status:'pending'});
        setCashflowList((prev) =>
          prev.map((cf) => (cf.cf_id === editingCashflowId ? { ...newCashflow, cf_id: editingCashflowId,totalAmount: parseFloat(newCashflow.totalAmount?.replace("$","").replace(",",""))  } : cf))
        );
        setAlert("Transacción actualizada correctamente.");
      } else {
    
  
      let addedCashflow;

      if (cf_id) {
        // Update existing cashflow
        await updateCashflow(newCashflow);
        setCashflows((prev) =>
          prev.map((cf) => (cf.expense_id === newCashflow.expense_id ? newCashflow : cf))
        );
      } else {
        // Check required fields
        if (!newCashflow.expense_date || !newCashflow.totalAmount || !newCashflow.project_id) {
          setAlert("Por favor, completa todos los campos obligatorios.");
          setAlertVariant("warning");
          return;
        }
        // Add new cashflow
        const updatedCashflow = {
          ...newCashflow,
          user_id: userId,
        };
  
        // Call API to save
        addedCashflow = await addCashflow(updatedCashflow);
  
        // Add the new transaction to the state
        if(addedCashflow.status == 201)
        {
            
            // Map `updatedCashflow` to the desired structure
            const transformedCashflow = {
              cf_id: addedCashflow.data.item_id,
              project_id: parseInt(updatedCashflow.project_id),
              name: projects.find((p) => p.id === parseInt(updatedCashflow.project_id))?.name , // Static or fetched dynamically
              category_id: parseInt(updatedCashflow.category_id),
              invoices: [{invoice_id:updatedCashflow.invoice_id}],
              concept: updatedCashflow.concept || "",
              cf_date: updatedCashflow.expense_date,
              notes: updatedCashflow.notes || "",
              category_name: categories.find((p) => p.category_id === parseInt(updatedCashflow.category_id))?.category_name, // Static or fetched dynamically
              transaction_type: updatedCashflow.transaction_type,
              operation_type: updatedCashflow.operation_type,
              totalAmount: parseFloat(updatedCashflow.totalAmount.replace("$", "").replace(",", "")),
              status:"pending"
            };

            
            setCashflowList((prev) => [
            { ...transformedCashflow|| Date.now() },
            ...prev,            
            ]);
            
            refetchNotifications();
        }
      }
    }
      setEditingCashflowId(null);
      // Reset the form
      setNewCashflow({
        expense_date: new Date().toISOString().split("T")[0],        
        category_id: "",
        bank_id: "",
        concept: "",
        transaction_type: "cash",
        totalAmount: 0.00,
        name:"",
        operation_type: "expense",
        notes: "",
      });
      setFormattedAmount("");
      setAlert("Transacción guardada correctamente.");
      setAlertVariant("success");
    
    } catch (error) {
      console.error("Error submitting petty cash:", error);
      setAlert("Error al enviar la Caja Chica.");
      setAlertVariant("danger");
    }
  };
  
  
  
  const normalizeAmount = (amount) => {
    if (typeof amount === "string") {
      // Remove non-numeric characters (except decimal point) and parse as a float
      return parseFloat(amount.replace(/[^0-9.-]+/g, ""));
    }
    return amount; // If it's already a number, return as is
  };

  const handleDeletePettyCashEntry = async (id) => {
    try{
      const queryParams ={
        request_id: id,
        user_id : userId
      }
      let response = await deletePettyCash(queryParams);
      if(response.status == 200)
      { 
        setPettyCashList((prev) => prev.filter((entry) => entry.request_id !== id));        
        setAlert("Transacción eliminada correctamente.");
        setAlertVariant("success");
      } else {
        // Handle unsuccessful response
        setAlert("No se pudo eliminar la transacción. Intenta de nuevo.");
        setAlertVariant("warning");
      }
     
    } catch (error) {
      setAlert("Error al eliminar la transacción.");
      setAlertVariant("danger");
    }
  };

  const totalAmount = pettyCashContainer
  .reduce((acc, entry) => acc + parseFloat(entry.totalAmount || 0), 0);

  return (
    <Container className="mt-4">
      <h1>Entradas de Flujo de Caja</h1>
      {alert && <Alert variant={alertVariant}>{alert}</Alert>}

      {/* Add Cashflow Form */}
      <Form className="mb-3">
        <Form.Group>
          <Form.Label>Obra</Form.Label>
          <Form.Select
            disabled  = {!newEntry}            
            value={newCashflow.project_id}
            onChange={(e) => setNewCashflow({ ...newCashflow, project_id: parseInt(e.target.value )})}
          >
            <option value="">-- Seleccionar Obra --</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Categoría</Form.Label>
          <Form.Select
            
            value={newCashflow.category_id}
            onChange={(e) => setNewCashflow({ ...newCashflow, category_id: parseInt(e.target.value )})}
          >
            <option value="">-- Seleccionar Categoría --</option>
            {categories.map((category) => (
              <option key={category.category_id} value={category.category_id}>
                {category.category_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Concepto</Form.Label>
          <Form.Control
            
            type="text"
            value={newCashflow.concept}
            onChange={(e) => setNewCashflow({ ...newCashflow, concept: e.target.value })}
          />
        </Form.Group>
        
          <Form.Group>
          <Form.Label>Nombre</Form.Label>
          <Form.Control
          
            type="text"            
            readOnly={roleId === 7}
            disabled={roleId === 7}            
            value={roleId === 7 ? userName : newCashflow.name}   
            onChange={(e) => setNewCashflow({ ...newCashflow, name: e.target.value })}
          />
        </Form.Group>
        
        <Row>
          <Col md={4}>
          <Form.Group>
          <Form.Label>Tipo de Operación</Form.Label>
          <div>
              <Form.Check
              
              inline
              type="radio"
              label="Ingreso"
              name="operation_type"
              value="income"
              checked={newCashflow.operation_type === "income"}
                onChange={(e) => setNewCashflow({ ...newCashflow, operation_type: e.target.value })}
              />
              <Form.Check
              
              inline
              type="radio"
              label="Egreso"
              name="operation_type"
              value="expense"
              checked={newCashflow.operation_type === "expense"}
                onChange={(e) => setNewCashflow({ ...newCashflow, operation_type: e.target.value })}
              />
          </div>
          </Form.Group>
          </Col>
          <Col md={2}>
          <Form.Group>
          <Form.Label>Tipo de Transacción</Form.Label>
          <div>
              <Form.Check
              
              inline
              type="radio"
              label="Fiscal"
              name="transaction_type"
              value="fiscal"
              checked={newCashflow.transaction_type === "fiscal"}
                onChange={(e) => setNewCashflow({ ...newCashflow, transaction_type: e.target.value })}
              />
              <Form.Check
              
              inline
              type="radio"
              label="Efectivo"
              name="transaction_type"
              value="cash"
              checked={newCashflow.transaction_type === "cash"}
              
                onChange={(e) => {setSelectedFolio(null); setSelectedVendorName(null);setNewCashflow({ ...newCashflow, transaction_type: e.target.value })}}
              />
          </div>
          </Form.Group>
          </Col>
          {newCashflow.transaction_type === "fiscal" && (
              <Col md={5}>
                <Form.Group>
                  <Form.Label>No. Factura</Form.Label>
                  <InputGroup>
                    <Form.Control
                    inline
                 
                    type="text"      
                    placeholder="Seleccionar factura"      
                    value={newCashflow.linked_invoices || selectedFolio}    
                    readOnly        
                    onChange={(e) => handleInputChange(e, "invoice_id",7)}
                    onBlur={handleBlur}  
                    />  
                    <InputGroup.Text>
                      <FaSearch style={{ cursor: "pointer" }} onClick={()=>handleSearchClick({start_date:newCashflow.cf_date,end_date:new Date(),status:['pending'],searchType:'amount',currency_id:2,charge:newCashflow.totalAmount})} />
                    </InputGroup.Text>
                    <Form.Control
                      inline   
                      disabled
                      class ="disabled-text"            
                      type="text"                          
                      value={selectedVendorName}    
                    
                    />
                  </InputGroup>
                </Form.Group>

                {/* Modal */}
                <InvoiceSelectionModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  invoices={invoices}                  
                  onSelect={handleInvoiceSelect}
                  onSearch={handleSearchClick}
                />
            </Col>
          )}
        </Row>
        <Row>
        <Col md={6}>
        <Form.Group>
            <Form.Label>Monto</Form.Label>
            <Form.Control
            
            type="text"            
            value={formattedAmount || newCashflow.totalAmount?.toLocaleString("es-MX", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            placeholder="0.00"
            onFocus={handleFocus}
           onChange={(e) => {
            handleInputChange(e, "totalAmount", 7);
           
          }}
            onBlur={handleBlur}   
            />
        </Form.Group>
        </Col>        
        <Col md={6}>
        <Form.Group>
          <Form.Label>Fecha de Transacción</Form.Label>
          <Form.Control
            
            type="date"
            value={newCashflow.cf_date}
            onChange={(e) => setNewCashflow({ ...newCashflow, cf_date: e.target.value })}
          />
        </Form.Group>
        </Col>
        </Row>
        
        <Form.Group>
          <Form.Label>Cuenta</Form.Label>
          <Form.Select
            
            disabled={roleId === 7}            
            value={roleId === 7 ? 2 : newCashflow.bank_id}
            onChange={(e) => setNewCashflow({ ...newCashflow, bank_id: e.target.value })}
          >
            <option value="">-- Seleccionar Cuenta --</option>
            {banks.map((bank) => (
              <option key={bank.source_id} value={bank.source_id}>
                {bank.source_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Notas</Form.Label>
          <Form.Control
            
            as="textarea"
            rows={3}
            value={newCashflow.notes}
            onChange={(e) => setNewCashflow({ ...newCashflow, notes: e.target.value })}
          />
        </Form.Group>
        <Button variant="primary" onClick={handleAddToPettyCash} disabled={editingCashflowId !== null}>
          Añadir
        </Button>
        {editingCashflowId !== null && (
          <Button variant="success" onClick={handleUpdatePettyCashEntry}>
            Actualizar
          </Button>
        )}
      </Form>

      {/* Cashflow Table */}
      <h3>Entradas de Caja Chica</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Obra</th>
            <th>Categoría</th>
            <th>Concepto</th>            
            <th>Monto</th>
            <th>Notas</th>  
            <th>Acciones</th>          
          </tr>
        </thead>
        <tbody>
          {pettyCashContainer.map((entry,index) => (
            <tr key={index}>
              <td>{entry.cf_date}</td>
              <td>{projects.find((p) => p.id === entry.project_id)?.name}</td>
              <td>{categories.find((cat) => cat.category_id === entry.category_id)?.category_name}</td>
              <td>{entry.concept}</td>
              <td className="text-right">{typeof entry.totalAmount === "number"?new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(entry.totalAmount):entry.totalAmount}</td>
              <td>{entry.notes}</td>     
              <td>
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => handleEditPettyCashEntry(index)}
                >
                  Editar
                </Button>
              </td>         
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between align-items-center mt-3">
      <h5>Total de Entradas:</h5>
      <h5>        
        <td className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalAmount)}</td>
      </h5>
    </div>
      <Button variant="success" onClick={() => handleSubmitPettyCash(totalAmount)}>
        Enviar Solicitud
      </Button>
      {/* Pending Petty Cash */}
      <h3>Solicitudes de Caja Chica</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Fecha</th>
            <th>Obra</th>
            <th>Total</th>
            <th>Notas</th>
            <th>Estatus</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {pettyCashList.map((pettyCash) => (
            <tr key={pettyCash.request_id}>
              <td>{formatDateToISO(new Date(pettyCash.created_at))}</td>              
              <td>{projects.find((p) => p.id === pettyCash.project_id)?.name}</td>              
              <td className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(pettyCash.amount)}</td>                                            
              <td>{pettyCash.reason}</td>
              <td>{statusTypeMap[pettyCash.status]}</td>
              <td>
                <Button
                  variant="success"
                  size="sm"
                  disabled = {pettyCash.status === "requested" }
                  onClick={() =>     handleEditPettyCash(pettyCash.request_id)}
                >
                  Editar
                </Button>
                <Button variant="danger"  size="sm" onClick={() => handleDeletePettyCashEntry(pettyCash.request_id)}>
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default CashflowPage;
