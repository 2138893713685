import React, { createContext, useContext, useState } from "react";

// Create the UserContext
const UserContext = createContext();

// Create a Provider Component
export const UserProvider = ({ children }) => {
  const [currentUserId, setCurrentUserId] = useState(null);

  // Simulate login for demo purposes
  const login = (userId) => setCurrentUserId(userId);
  const logout = () => setCurrentUserId(null);

  return (
    <UserContext.Provider value={{ currentUserId, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
export const hasPermission = (userPrivileges, module, requiredPermissions) => {
  if (!userPrivileges || !Array.isArray(userPrivileges)) return false;

  // Check if user has any of the required permissions
  return userPrivileges.some((privilege) => {
    const [privilegeModule, capability] = privilege.split(":");
    return privilegeModule === module && requiredPermissions.includes(capability);
  });
};

// Hook to use UserContext
export const useUser = () => useContext(UserContext);
