import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Table, Button, Form, Alert, Container } from "react-bootstrap";
import {
  getProjects,
  getApprovedInquiries,
  getPOs,
  getVendors,
  getUsers,
  addPO,
  deletePO,
} from "../services/api";

const PORequestPage = ({ userId }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [approvedInquiries, setApprovedInquiries] = useState([]);
  const [POs, setPOs] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [users, setUsers] = useState([]);
  const [alert, setAlert] = useState(null);
  const [poItems, setPOItems] = useState([]);
  const [newPO, setNewPO] = useState(null);
  const [alertVariant, setAlertVariant] = useState('info');

  // Fetch projects on load
  useEffect(() => {
    const fetchProjects = async () => {
      const projectsData = await getProjects();
      setProjects(projectsData);
    };
    fetchProjects();
  }, []);

  // Fetch POs and approved inquiries when a project is selected
  useEffect(() => {
    const fetchProjectData = async () => {
      if (!selectedProjectId) return;

      const [POsData, inquiriesData] = await Promise.all([
        getPOs(selectedProjectId),
        getApprovedInquiries(selectedProjectId),
      ]);

      setPOs(POsData);
      setApprovedInquiries(inquiriesData);
    };

    fetchProjectData();
  }, [selectedProjectId]);

  // Fetch vendors and users for dropdowns
  useEffect(() => {
    const fetchDropdownData = async () => {
      const usersData = await getUsers();
      const vendorsData = await getVendors();
      setVendors(vendorsData);
      setUsers(usersData);
    };
    fetchDropdownData();
  }, []);

  // Add a new PO with empty items
  const handleAddNewPO = () => {
    setNewPO({
      po_number: "",
      buyerId: "",
      vendorId: "",
      status_id: "pendiente",
    });
    setPOItems([]);
  };

  // Add a new PO item
  const handleAddPOItem = () => {
    setPOItems((prevItems) => [
      ...prevItems,
      {
        inquiry_id: "",
        quantity: 0,
        remaining_quantity: 0,
        unit_cost: 0,
        item_code: '',
        total_cost: 0,
      },
    ]);
  };

  // Update PO item
  const handlePOItemChange = (index, field, value) => {
    const updatedItems = [...poItems];
    const item = updatedItems[index];

    if (field === "inquiry_id") {
      const inquiry = approvedInquiries.find(
        (inq) => inq.inquiry_id === Number(value)
      );
      updatedItems[index] = {
        ...item,
        inquiry_id: inquiry.inquiry_id,      
        description: inquiry.description,
        unitCost: inquiry.unitCost,
        item_code: inquiry.costCode,
        quantity: 0,
        total_cost: 0,
      };
    } else if (field === "quantity") {

      const newQuantity = Number(value) || 0;
      const cumulativeQuantity = updatedItems.reduce((sum, currentItem, idx) => {
        if (
          currentItem.inquiry_id === item.inquiry_id &&
          idx !== index // Exclude the current row being edited
        ) {
          return sum + currentItem.quantity;
        }
        return sum;
      }, newQuantity);

      const inquiry = approvedInquiries.find(
        (inq) => inq.inquiry_id === item.inquiry_id
      );

      updatedItems[index] = {
        ...item,
        quantity: newQuantity,
        remaining_quantity: inquiry.quantity - cumulativeQuantity,
        total_cost: item.unitCost * newQuantity,
      };
    
    }

    setPOItems(updatedItems);
  };

  // Save the new PO
  const handleSaveNewPO = async () => {
    if (!newPO.po_number || !newPO.buyerId || !newPO.vendorId || poItems.length === 0) {
      setAlert("Por favor complete todos los campos obligatorios.");
      setAlertVariant('info');
      return;
    }

    const invalidItem = poItems.find(
      (item) =>  item.remaining_quantity < 0
    );

    if (invalidItem) {
      setAlert("No puede pedir más artículos de los que están disponibles en la requisición.");
      setAlertVariant('warning');
      return;
    }

    const totalAmount = poItems.reduce((sum, item) => sum + item.total_cost, 0);

    try {
      await addPO(selectedProjectId, {
        ...newPO,
        items: poItems,
        userId,
        totalAmount        
      });

      setAlert("Orden de compra agregada correctamente.");
      setAlertVariant('success');
      setNewPO(null);
      setPOItems([]);
    } catch (error) {
      console.error("Error al guardar la orden de compra:", error);
      if ( error.response.data.error.includes("Duplicated entry: PO number"))
      {
        setAlert("Error: Número de PO duplicada");
        setAlertVariant('danger');  
      }
      else
      {
        setAlert("Error al guardar la orden de compra.");
        setAlertVariant('danger');
      }
    }
  };

  return (
    <Container className="mt-4">
      <h1>Órdenes de Compra</h1>
      {alert && <Alert variant={alertVariant}>{alert}</Alert>}

      {/* Project Selection */}
      <Form.Group>
        <Form.Label>Selecciona un Proyecto:</Form.Label>
        <Form.Select
          value={selectedProjectId || ""}
          onChange={(e) => setSelectedProjectId(e.target.value)}
        >
          <option value="">-- Seleccionar Proyecto --</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* POs Table */}
      {POs.length > 0 && (
        <>
          <h3 className="mt-3">Órdenes de Compra Existentes</h3>
          <Table bordered hover>
            <thead>
              <tr>
                <th>Número</th>
                <th>Comprador</th>
                <th>Descripción</th>
                <th>Proveedor</th>
                <th>Total</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {POs.map((po) => (
                <tr key={po.po_id}>
                  <td>{po.po_number}</td>
                  <td>{po.buyer}</td>
                  <td>{po.description}</td>                
                  <td>{po.vendorId}</td>
                  <td>${Number(po.total_amount).toFixed(2)}</td>
                  <td>{po.status}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => deletePO(po.po_id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      {/* Add New PO */}
      <Button className="mt-3" onClick={handleAddNewPO}>
        Nueva Orden de Compra
      </Button>
      {newPO && (
        <>
          <h3 className="mt-3">Nueva Orden de Compra</h3>
          <Form>
            <Form.Group>
              <Form.Label>Número de Orden</Form.Label>
              <Form.Control
                type="text"
                value={newPO.po_number}
                onChange={(e) => setNewPO({ ...newPO, po_number: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                type="text"
                value={newPO.description}
                onChange={(e) => setNewPO({ ...newPO, description: e.target.value })}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Comprador</Form.Label>
              <Form.Select
                onChange={(e) => setNewPO({ ...newPO, buyerId: e.target.value })}
              >
                <option value="">-- Seleccionar --</option>
                {users.map((user) => (
                  <option key={user.user_id} value={user.user_id}>
                    {user.user_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Proveedor</Form.Label>
              <Form.Select
                onChange={(e) => setNewPO({ ...newPO, vendorId: e.target.value })}
              >
                <option value="">-- Seleccionar --</option>
                {vendors.map((vendor) => (
                  <option key={vendor.vendor_id} value={vendor.vendor_id}>
                    {vendor.vendor_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>

          {/* PO Items */}
          <Button className="mt-3" onClick={handleAddPOItem}>
            Agregar Artículo
          </Button>
          <Table bordered className="mt-3">
            <thead>
              <tr>
                <th>Requisición</th>
                <th>Cantidad</th>
                <th>Costo Unitario</th>
                <th>Total</th>
                <th></th> 
              </tr>
            </thead>
            <tbody>
              {poItems.map((item, index) => {
                 const isOverQuantity = item.remaining_quantity < 0;
                 return(
                  <tr
                  key={index}
                  style={isOverQuantity ? { backgroundColor: "#f8d7da" } : {}}
                  >                
                  <td>
                    <Form.Select
                      onChange={(e) =>
                        handlePOItemChange(index, "inquiry_id", e.target.value)
                      }
                    >
                      <option value="">-- Seleccionar --</option>
                      {approvedInquiries
                      .filter((inq) => inq.status === "Autorizado") // Filter for approved inquiries
                      .map((inq) => (
                        <option key={inq.inquiry_id} value={inq.inquiry_id}>
                          {inq.description}
                        </option>
                      ))}
                    </Form.Select>
                  </td>               
                  <td>
                  <Form.Control
                      type="number"
                      value={item.quantity}
                      onChange={(e) => {
                        const inputValue = Number(e.target.value);
                        if (inputValue >= 0) {
                          handlePOItemChange(index, "quantity", inputValue);
                        }
                      }}
                      onBlur={(e) => {
                        // If empty or invalid, reset to 0
                        if (e.target.value === "" || Number(e.target.value) < 0) {
                          handlePOItemChange(index, "quantity", 0);
                        }
                      }}
                      min="0" // Ensures the field can't decrement below 0
                    />
                  </td>    
                  <td className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(!isNaN(parseFloat(item.unitCost))? parseFloat(item.unitCost): 0)}</td>                  
                                                        
                  <td className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.total_cost)}</td>                                            
                  <td className="text-center">
                    {isOverQuantity && (<FontAwesomeIcon icon={faExclamationTriangle}
                        color="red"
                        title="La cantidad solicitada excede la cantidad disponible"
                      />
                    )}
                  </td>
                </tr>
                 );
              })}
            </tbody>
          </Table>
          <Button variant="success" onClick={handleSaveNewPO}>
            Guardar Orden
          </Button>
        </>
      )}
    </Container>
  );
};

export default PORequestPage;
