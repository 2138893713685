import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import { addProject, getUsers } from "../services/api";
import { useNavigate } from "react-router-dom";

const AddProjectPage = ({userId}) => {
  const [projectData, setProjectData] = useState({
    name: "",
    description: "",
    project_manager: "",
    start_date: "",
    end_date: "",
  });
  const [users, setUsers] = useState([]); // List of users for the dropdown
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch users for the project manager dropdown
    const fetchUsers = async () => {
      try {
        const userList = await getUsers();
        setUsers(userList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjectData({ ...projectData, [name]: value ,user_id:userId});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addProject({...projectData,user_id:userId});
      setAlert("Proyecto agregado exitosamente.");
      setTimeout(() => navigate("/projects"), 1000);
    } catch (error) {
      console.error("Error adding project:", error);
      setAlert("Error al agregar el proyecto.");
    }
  };

  return (
    <Container className="mt-4">
      <h2>Agregar Proyecto</h2>
      {alert && <Alert variant="info">{alert}</Alert>}
      <Form onSubmit={handleSubmit}>
        {/* Project Name */}
        <Form.Group className="mb-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={projectData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* Project Description */}
        <Form.Group className="mb-3">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            value={projectData.description}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* Project Manager */}
        <Form.Group className="mb-3">
          <Form.Label>Administrador del Proyecto</Form.Label>
          <Form.Select
            name="project_manager"
            value={projectData.project_manager}
            onChange={handleChange}
            required
          >
            <option value="">-- Seleccionar Usuario --</option>
            {users.map((user) => (
              <option key={user.user_id} value={user.user_id}>
                {user.user_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        {/* Start Date */}
        <Form.Group className="mb-3">
          <Form.Label>Fecha de Inicio</Form.Label>
          <Form.Control
            type="date"
            name="start_date"
            value={projectData.start_date}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* End Date */}
        <Form.Group className="mb-3">
          <Form.Label>Fecha de Fin</Form.Label>
          <Form.Control
            type="date"
            name="end_date"
            value={projectData.end_date}
            onChange={handleChange}
          />
        </Form.Group>

        <Button type="submit" variant="success">
          Guardar Proyecto
        </Button>
      </Form>
    </Container>
  );
};

export default AddProjectPage;
