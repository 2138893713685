// src/pages/Users.js
import React, { useEffect, useState } from "react";
import { getUsers, getUserPrivileges, getPermissions } from "../services/api"; // Import the necessary API functions

const Users = ({userId}) => {
  const [users, setUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch users, permissions, and user permissions when the component mounts
    const fetchData = async () => {
      try {
        const [usersData,permissionsData, userPermissionsData] = await Promise.all([
          getUsers(),
          getPermissions(),
          getUserPrivileges(userId),
        ]);

        setUsers(usersData);
        setPermissions(permissionsData);
        setUserPermissions(userPermissionsData);
        setLoading(false);
      } catch (err) {
        setError("Fallo al obtener usuarios y permisos");
        setLoading(false);
      }
    };

    fetchData(); // Call the function to fetch data
  }, []);

  // Map permissions for each user
  const getUserPermissionsList = (userId) => {
    const userPerms = userPermissions;
    return userPerms.map((perm) => {
      const permission = permissions.find((p) => p.permission_id === perm.permission_id);
      return `${permission?.module_name || "N/A"} (${permission?.permission_level || "N/A"})`;
    });
  };

  // Handle loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Handle error state
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h2 className="text-center mt-4">Lista de Usuarios</h2>
      <table className="table table-striped mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Email</th>
            <th>Permisos</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.user_name}</td>
              <td>{user.email}</td>
              <td>
                {getUserPermissionsList(user.user_id).length > 0 ? (
                  <ul>
                    {getUserPermissionsList(user.user_id).map((perm, i) => (
                      <li key={i}>{perm}</li>
                    ))}
                  </ul>
                ) : (
                  "No tiene permisos asignados"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Users;
