import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';  // Import BrowserRouter
import App from './App';
import { AlertProvider } from "./components/Alerts";
import './index.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 


ReactDOM.render(
  <BrowserRouter>  {/* Wrap the entire app here */}
    <AlertProvider>    
      <App />
    </AlertProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

