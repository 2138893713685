import React, { useEffect, useState } from "react";
import { Table, Button, Form, Alert, Container, Modal } from "react-bootstrap";
import { transactionTypeMap, operationTypeMap } from "../types/CashflowModuleTypes";
import { useNotifications } from "../context/NotificationsContext";
import { useAlert } from "../components/Alerts";
import {
  getProjects,
  getCategories,
  getCashflows,
  updateInvoiceStatus,
  updateCashflow,
  updatePettyCash
} from "../services/api"; // Adjust the imports as needed

const IncomingCashflowPage = ({ userId }) => {
  const { refetchNotifications } = useNotifications();
  const [invoices, setInvoices] = useState([]);
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);    
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [selectedCashflows, setSelectedCashflows] = useState([]); // For displaying cashflow details
  const [showDetailsModal, setShowDetailsModal] = useState(false); // To control the details modal visibility
  const { alert, showAlert, clearAlert } = useAlert();

  // Fetch projects, categories, and incoming invoices on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = {
          status: ["requested"],
          petty_cash: 1,
        };
        const [projectsData, categoriesData, invoicesData] = await Promise.all([
          getProjects(),
          getCategories(),
          getCashflows(queryParams),
        ]);

        setProjects(projectsData || []);
        setCategories(categoriesData || []);
        setInvoices(invoicesData || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        showAlert("Error al obtener los datos iniciales.","danger");        
      }
    };
    fetchData();
  }, []);

  const fetchCashflowDetails = async (requestId) => {
    try {
      const queryParams = {
        petty_cash_id: requestId,        
        status:["pending"]
      };
      const cashflows = await getCashflows(queryParams);
      setSelectedCashflows(cashflows);
      setShowDetailsModal(true);
    } catch (error) {
      console.error("Error fetching cashflow details:", error);
      showAlert("Error al obtener detalles de la solicitud.","danger");      
    }
  };

  const handleAccept = async (invoiceId, projectId, categoryId) => {
    try {
      await updateInvoiceStatus(invoiceId, { status: "accepted", project_id: projectId, category_id: categoryId });
      setInvoices((prev) => prev.filter((invoice) => invoice.id !== invoiceId));
      showAlert("Factura aceptada exitosamente.","success");
      
    } catch (error) {
      console.error("Error accepting invoice:", error);
      showAlert("Error al aceptar la factura.","danger");      
    }
  };

  const handleReject = async (invoiceId) => {
    try {
      await updateInvoiceStatus(invoiceId, { status: "rejected" });
      setInvoices((prev) => prev.filter((invoice) => invoice.id !== invoiceId));
      showAlert("Factura rechazada exitosamente.","success");      
    } catch (error) {
      console.error("Error rejecting invoice:", error);
      showAlert("Error al rechazar la factura.","danger");      
    }
  };

  const handleEditSave = async (action, invoice = editingInvoice) => {
    if (!invoice) {
      showAlert("No se seleccionó una factura.","danger");      
      return;
    }
    try {
      const { request_id, project_id, category_id, concept, totalAmount, transaction_type, uuid } = invoice;

      if (action === "accept") {
        if (transaction_type === "fiscal" && (!uuid || uuid === "")) {
          showAlert("UUID faltante","warning");          
          setEditingInvoice(null);
          return;
        }

        var response;
        response = await updatePettyCash({
          request_id: request_id,
          user_id: userId,
          status: "approved",
          project_id: project_id,
          category_id: category_id,
        });
      } else if (action === "reject") {
        response = await updatePettyCash({
          request_id: request_id,
          user_id: userId,
          status: "rejected",
          project_id: project_id,
          category_id: category_id,
        });
      } else {
        showAlert("Acción faltante","danger");        
        setEditingInvoice(null);
        return;
      }
    
      setInvoices((prev) => prev.filter((invoice) => invoice.request_id !== request_id));

      setEditingInvoice(null);
      refetchNotifications();
      showAlert(action === "accept" ? "Factura aceptada exitosamente." : "Factura rechazada exitosamente.","success");    
    } catch (error) {
      if (error.status == 409)
      {
          showAlert("Factura ya aprobada, sólo se puede rechazar","warning");          
      }
      else
      {
        console.error("Error al editar factura:", error);
        showAlert("Error al editar la factura.","danger");        
      }
    }
  };

  return (
    <Container className="mt-4">
      <h1>Revisión de Facturas Entrantes</h1>
      {/* Alert */}
      {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}

      <Table bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Fecha</th>
            <th>Obra</th>
            <th>Emisor</th>            
            <th>Monto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr key={invoice.id}>
              <td data-label="ID">{invoice.request_id}</td>
              <td>{invoice.created_at}</td>
              <td data-label="project_name">{invoice.project_name}</td>
              <td>{invoice.name}</td>              
              <td className="text-right">
                {new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(invoice.amount)}
              </td>
              <td>
                <Button className="btn-ripple" variant="info" onClick={() => fetchCashflowDetails(invoice.request_id)}>
                  Ver Detalles
                </Button>{" "}
                <Button className="btn-ripple " variant="success"  onClick={() => handleEditSave("accept", invoice)}>
                  Aceptar
                </Button>{" "}
                <Button className="btn-custom " variant="danger" onClick={() => handleEditSave("reject", invoice)}>
                  Rechazar
                </Button>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Detalles de Caja Chica</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="table-container">
            <Table class="modal-table" id="modal-table" striped bordered hover >
              <thead>
                <tr>
                  <th key="Fecha">Fecha</th>        
                  <th key="UUID">UUID</th>      
                  <th key="Emisor">Emisor</th>  
                  <th key="Tipo">Tipo</th>
                  <th key="Categoria">Categoría</th>
                  <th key="Concepto">Concepto</th>
                  <th key="Monto">Monto</th>
                  <th key="Notas">Notas</th>
                </tr>
              </thead>
              <tbody>
                {selectedCashflows.map((cashflow) => (
                  <tr key={cashflow.cf_id}>
                    <td data-label="Fecha">{cashflow.cf_date}</td>        
                    <td data-label="UUID">{cashflow.uuid}</td>        
                    <td data-label="Emisor">{cashflow.nombre_emisor}</td>                  
                    <td data-label="Tipo">{transactionTypeMap[cashflow.transaction_type]}</td>
                    <td data-label="Categoria">{cashflow.category_name}</td>
                    <td data-label="Concepto">{cashflow.concept}</td>
                    <td data-label="Monto">
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(cashflow.totalAmount)}
                    </td>
                    <td data-label="Notas">{cashflow.notes}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default IncomingCashflowPage;
