import React, {  useEffect,useState } from "react";
import { Link,useLocation  } from "react-router-dom";
import { Navbar as BootstrapNavbar, Nav, NavDropdown } from "react-bootstrap";
import { useNotifications } from "../context/NotificationsContext";
import { hasAccess } from "../utils/AccessControl";
import { FaHome,FaBell, FaThList,FaProjectDiagram, FaShoppingCart, FaUser, FaCashRegister,FaExchangeAlt,FaCog,FaChartLine} from "react-icons/fa";

const Navbar = ({ isLoggedIn, userPrivileges,fetchUserPrivileges,rolePrivilages,userId }) => {
const activeModules = ["banks", "projects","purchasing"];
const location = useLocation();
const { notificationsCount } = useNotifications();
const [menuOpen, setMenuOpen] = useState(false); 
const isActive = (path) => location.pathname === path;

useEffect(() => {
  if (isLoggedIn && userId) {
    fetchUserPrivileges(userId);
  }
}, [isLoggedIn, userId, fetchUserPrivileges]);

const handleMenuClick = () => {
  setMenuOpen(!menuOpen); // Toggle menu open/close
};

const handleItemClick = () => {
  setMenuOpen(false); // Close menu when an item is clicked
};


  return (
    <BootstrapNavbar bg="light" expand="lg" className="mb-4" expanded={menuOpen}>
      <div className="container-fluid">        
        <BootstrapNavbar.Brand as={Link} to="/" className="text-white">ERP System - PROEDESA</BootstrapNavbar.Brand>
        
        <BootstrapNavbar.Toggle aria-controls="navbarNav" onClick={handleMenuClick} />
        <BootstrapNavbar.Collapse id="navbarNav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className={isActive("/") ? "active" : ""}  onClick={handleItemClick}>  
            <FaHome  className="icon-margin"/>Inicio
            </Nav.Link>
           
            <Nav.Link as={Link} to="/dashboard" className={isActive("/dashboard") ? "active" : ""}  onClick={handleItemClick}>
              Login
            </Nav.Link>
            {isLoggedIn && (
              <>
                <NavDropdown title={<><FaProjectDiagram className="icon-margin"/>Proyectos</>} id="projects-dropdown">
                  <NavDropdown.Item as={Link} to="/projects"  onClick={handleItemClick}>Ver Proyectos</NavDropdown.Item>
                  {userPrivileges?.includes("projects:write") && (
                    <NavDropdown.Item as={Link} to="/projects/add"  onClick={handleItemClick}>
                      Agregar Proyecto
                    </NavDropdown.Item>
                  )}
                  {userPrivileges?.includes("projects:write") && (
                    <NavDropdown.Item as={Link} to="/projects/edit" onClick={handleItemClick}>
                      Editar Proyecto
                    </NavDropdown.Item>
                  )}
                  {userPrivileges?.includes("projects:write") && (
                    <NavDropdown.Item as={Link} to="/projects/delete"  onClick={handleItemClick}>
                      Eliminar Proyecto
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
                {hasAccess(userPrivileges, rolePrivilages, "inquiries:read", activeModules, "purchasing") && (
                    
                  <NavDropdown title={<><FaShoppingCart className="icon-margin"/>Compras</>} id="purchase-dropdown">
                    {hasAccess(userPrivileges, rolePrivilages, "inquiries:approve", activeModules, "purchasing") && 
                      (
                        <NavDropdown.Item as={Link} to="/approvals"  onClick={handleItemClick}>
                          Aprobaciones
                        </NavDropdown.Item>
                      ) }

                    {hasAccess(userPrivileges, rolePrivilages, "inquiries:write", activeModules, "purchasing") && (
                      <NavDropdown.Item as={Link} to="/inquiries" onClick={handleItemClick}>
                        Requisiciones
                      </NavDropdown.Item>
                    )}
                    {hasAccess(userPrivileges, rolePrivilages, "po:write", activeModules, "purchasing") && (
                      <NavDropdown.Item as={Link} to="/pos" onClick={handleItemClick}>
                        Órdenes de Compra
                      </NavDropdown.Item>                
                    )}
                  </NavDropdown>
                )}
                {hasAccess(userPrivileges, rolePrivilages, "banks:read", activeModules, "banks") && (
                    <NavDropdown title={<><FaExchangeAlt className="icon-margin"/>Bancos</>} id="banks-dropdown">
                    
                    <NavDropdown.Item as={Link} to="/bank-statement" onClick={handleItemClick}>
                      Movimientos
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/bank-reconciliation" onClick={handleItemClick}>
                      Conciliación
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/pattern-rules" onClick={handleItemClick}>
                      Reglas de asociación
                    </NavDropdown.Item>
                </NavDropdown>
                )}
              

                <NavDropdown title={<><FaCashRegister className="icon-margin"/>Flujo de Caja</>} id="cashflow-dropdown"> 
                  {userPrivileges?.includes("approvals:approve") && (
                    <NavDropdown.Item as={Link} to="/invoice-inbox" onClick={handleItemClick}>
                    Buzón de Facturas
                  </NavDropdown.Item>
                  )}
                <NavDropdown.Item as={Link} to="/cashflow" onClick={handleItemClick}>
                  Entradas de Flujo de Caja
                </NavDropdown.Item>  
                 { userPrivileges?.includes("cashflow:write") && (                  
                  <NavDropdown.Item as={Link} to="/cashflow-reports" onClick={handleItemClick}>
                    Reportes de Flujo de Caja
                  </NavDropdown.Item>
                )}
              </NavDropdown>

              { userPrivileges?.includes("system:read") && (  
                
                <NavDropdown title={<><FaCog className="icon-margin"/>Sistema</>} id="system-dropdown">                    
                    <NavDropdown.Item as={Link} to="/users" onClick={handleItemClick}>
                      Usuarios
                    </NavDropdown.Item>                       
                    <NavDropdown.Item as={Link} to="/system/import" onClick={handleItemClick}>
                      Importar
                    </NavDropdown.Item>   
                    <NavDropdown.Item as={Link} to="/system/config" onClick={handleItemClick}>
                      Configuración
                    </NavDropdown.Item>                  
                </NavDropdown>
              )}
              { userPrivileges?.includes("catalogs:read") && (
                <NavDropdown title={<><FaThList className="icon-margin"/>Catalogos</>} id="catalogs-dropdown">
                    <NavDropdown.Item as={Link} to="/catalogs/vendors" onClick={handleItemClick}>
                      Proveedores
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/catalogs/customers" onClick={handleItemClick}>
                      Clientes
                    </NavDropdown.Item>
                </NavDropdown>
              )}
              { userPrivileges?.includes("reports:read") && ( 
                
                <NavDropdown title={<><FaChartLine className="icon-margin"/>Reportes</>} id="reports-dropdown">
                    <NavDropdown.Item as={Link} to="/reports/projects" onClick={handleItemClick}>
                      Proyectos
                    </NavDropdown.Item>                    
                    <NavDropdown.Item as={Link} to="/reports/cashflow" onClick={handleItemClick}>
                      Flujo de Efectivo
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/reports/pettyCash" onClick={handleItemClick}>
                      Caja Chica
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/reports/vendors" onClick={handleItemClick}>
                      Proveedores
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/reports/customers" onClick={handleItemClick}>
                      Clientes
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/reports/budgets" onClick={handleItemClick}>
                      Presupuestos
                    </NavDropdown.Item>
                  </NavDropdown>
              )}

                <Nav.Link as={Link} to="/notifications">
                 <FaBell/> Notificaciones{" "}
                  {notificationsCount > 0 && (
                    <span className="badge bg-danger">{notificationsCount}</span>
                  )}
                </Nav.Link>
              </>
            )}

{isLoggedIn && (
  <NavDropdown title="Perfil" id="profile-dropdown">
    <NavDropdown.Item as={Link} to="/profile">
      Mi Perfil
    </NavDropdown.Item>
    <NavDropdown.Item as={Link} to="/logout">
      Cerrar Sesión
    </NavDropdown.Item>
  </NavDropdown>
)}

          </Nav>
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  );
};

export default Navbar;
