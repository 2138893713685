import React from "react";
import { Navigate } from "react-router-dom";

const Dashboard = ({ handleLogout }) => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  // Redirect to login if not logged in
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
     <div className="welcome-container">
        <h1 className="welcome-message">
          Bienvenido al <span>ERP de construcción</span>
        </h1>
        <p className="welcome-subheading">Tu herramienta para gestionar proyectos y recursos eficientemente.</p>
      </div>

      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Dashboard;
