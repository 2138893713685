import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Form, Alert, Container, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useAlert } from "../components/Alerts";
import {
  getProjects,
  getBudget,
  getBudgetItems,
  getInquiries,
  addInquiry,
  approveInquiry,
  getUnits
} from "../services/api";

const InquiriesPage = ({userPrivileges}) => {  
  const location = useLocation(); // Query parameters  
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [budgets, setBudgets] = useState([]);
  const [selectedBudgetId, setSelectedBudgetId] = useState(null);
  const [budgetItems, setBudgetItems] = useState([]);
  const [inquiryItems, setInquiryItems] = useState([]); // Existing inquiries
  const [isLoading,setIsLoading] = useState(false)
  const [units, setUnits] = useState([]);
  const [newInquiryItems, setNewInquiryItems] = useState([]); // New entries  
  const { alert, showAlert, clearAlert } = useAlert();
  const storedUserId = localStorage.getItem("userId");
  const quantityRefs = useRef([]);
   
  const queryParams = new URLSearchParams(location.search);
  const initialProjectId = queryParams.get("project_id");
  const initialBudgetId = queryParams.get("budget_id");

  const fetchBudgets = async () => {
    if (!selectedProjectId) return;
    const budgetData = await getBudget(selectedProjectId);
    setBudgets(budgetData);
    
    if (initialBudgetId) {
      setSelectedBudgetId(initialBudgetId);
    }
    else
    {
      setSelectedBudgetId(null);
    }
    setBudgetItems([]);
    setInquiryItems([]);
    setNewInquiryItems([]);
  };

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const unitsData = await getUnits(); // Call API to get units
        setUnits(unitsData); // Store units in state
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    fetchUnits();
  }, []);



  // Fetch all projects on component load
  useEffect(() => {
    const fetchProjects = async () => {
      const projectsData = await getProjects();
      setProjects(projectsData);
      if (initialProjectId) {
        setSelectedProjectId(initialProjectId);
      }
    };
    fetchProjects();
  }, [initialProjectId]);

  // Fetch budgets when a project is selected
  useEffect(() => {    
    fetchBudgets();
  }, [selectedProjectId,initialBudgetId]);

  // Fetch budget items and inquiries when a budget is selected
  useEffect(() => {
    const fetchBudgetItemsAndInquiries = async () => {
      if (!selectedBudgetId) return;

      try {
        setIsLoading(true);
        const items = await getBudgetItems(selectedProjectId, selectedBudgetId);
        setBudgetItems(items);
        const statusOrder = ["Pendiente", "Autorizado", "Rechazado"];

        const inquiries = await getInquiries(selectedProjectId, selectedBudgetId);
        const sortedInquiries = inquiries.sort((a, b) => {
          const statusComparison =  statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
          if (statusComparison !== 0) {
            return statusComparison; // If statuses are different, sort by status
          }
        
          // If statuses are the same, sort by ID
          return b.inquiry_id - a.inquiry_id ; 
        });
        setInquiryItems(sortedInquiries); // Existing inquiries
        setNewInquiryItems([]); // Reset new items
      } catch (error) {
        console.error("Error fetching data:", error);
        showAlert("Error al obtener los datos del presupuesto y solicitudes.","danger");        
      }
      finally
      {
        setIsLoading(false);
      }
    };
    fetchBudgetItemsAndInquiries();
  }, [selectedBudgetId]);

  // Add a new empty row to the new inquiries table
  const handleAddRow = () => {
    setNewInquiryItems((prev) => [
      ...prev,
      { id: "", description: "", quantity: 0, unitCost: 0, total_cost: 0 },
    ]);
    quantityRefs.current.push(React.createRef());
  };

  // Handle changes in the new inquiry table rows
  const handleRowChange = (index, field, value,getFocus = false) => {
    const updatedItems = [...newInquiryItems];
    if (field === "item_id") {
      const selectedItem = budgetItems.find((item) => item.id === Number(value));

      if (selectedItem) {
        
        const selectedUnit = units.find((unit) => unit.unit_name === selectedItem.unitOfMeasure);
        if (selectedUnit) {
          const updatedRows = inquiryItems.map((row) => ({
            ...row,
            unitOfMeasure: selectedUnit.unit_id, // Set the value dynamically
          }));
          setInquiryItems(updatedRows);
        }
      

      updatedItems[index] = {
        ...updatedItems[index],
        item_id: selectedItem.id,
        costCode: selectedItem.costCode,
        description: selectedItem.description,
        unitCost: selectedItem.unitCost,
        unitOfMeasure : selectedUnit? selectedUnit.unit_id : "",
        remaining_quantity: selectedItem.remaining_quantity,
        costCode: selectedItem.costCode,
        quantity: updatedItems[index].quantity || 0,
        total_cost: selectedItem.unitCost * (updatedItems[index].quantity || 0),
      };
      setNewInquiryItems(updatedItems);
      if (getFocus) setTimeout(() => quantityRefs.current[index]?.current?.focus(), 0);
    }
    } else if (field === "quantity") {
      updatedItems[index] = {
        ...updatedItems[index],
        quantity: Number(value),
        total_cost: updatedItems[index].unitCost * Number(value),
      };
      setNewInquiryItems(updatedItems);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      await addInquiry(selectedProjectId, selectedBudgetId, storedUserId, newInquiryItems);
      showAlert("Solicitud creada exitosamente.","success");             
      setNewInquiryItems([]);
    } catch (error) {
      showAlert("Error al crear la solicitud.","danger");      
      console.error(error);
    }
  };
  
  const handleApproveInquiry = async (inquiryId) => {
    try {
      await approveInquiry(selectedProjectId,inquiryId, storedUserId); // Call API
      setInquiryItems((prevItems) =>
        prevItems.map((item) =>
          item.inquiry_id === inquiryId ? { ...item, status: "Autorizado" } : item
        )     
      );
      await fetchBudgets();

      showAlert("Solicitud aprobada exitosamente.","success");      
    } catch (error) {
      console.error("Error al aprobar la solicitud:", error);
      showAlert("Error al aprobar la solicitud.","warning");      
    }
  };
  const totalAmount = inquiryItems.reduce((sum, item) => sum + (item.unitCost * item.quantity), 0);  
  return (
    <Container className="mt-4">
      <h1>Gestión de Solicitudes</h1>

      {/* Select Project */}
      <Form.Group>
        <Form.Label>Selecciona un Proyecto:</Form.Label>
        <Form.Select
          onChange={(e) => setSelectedProjectId(e.target.value)}
          value={selectedProjectId || ""}
        >
          <option value="">-- Seleccionar --</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {/* Select Budget */}
      {budgets.length > 0 && (
        <Form.Group className="mt-3">
          <Form.Label>Selecciona un Presupuesto:</Form.Label>
          <Form.Select
            onChange={(e) => setSelectedBudgetId(e.target.value)}
            value={selectedBudgetId || ""}
          >
            <option value="">-- Seleccionar --</option>
            {budgets.map((budget) => (
              <option key={budget.budget_id} value={budget.budget_id}>
                {budget.description} (Restante: ${Number(budget.remaining_budget).toLocaleString()})
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}

      {isLoading ? (
        <Spinner animation="border" style={{ marginTop: "20px" }}/>
      ) : (
        <>     
          {/* Existing Inquiries */}
          {inquiryItems.length > 0 && (
            <>
              <h3 className="mt-4">Solicitudes Existentes</h3>
              <Table className="compressedTable" striped bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Descripción</th>
                    <th>Cantidad</th>
                    <th>Unidad</th>
                    <th>Costo Unitario</th>
                    <th>Costo Total</th>
                    <th>Estatus</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {inquiryItems.map((item) => (
                    <tr key={item.id}>
                      <td data-label="ID">{item.inquiry_id}</td>
                      <td data-label="Descripción">{item.description}</td>
                      <td data-label="Cantidad" className="text-right">{new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.quantity)} </td>
                      <td data-label="Unidad">{item.unitOfMeasure}</td>
                      <td data-label="Costo Unitario" className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.unitCost)}</td>
                      <td data-label="Costo Total" className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.quantity * item.unitCost)}</td>                  
                      <td data-label="Estatus">{item.status}</td>
                      <td>
                        {/* Show Approve button only if user has privileges and inquiry is pending */}
                        {item.status === "Pendiente" && userPrivileges?.includes("inquiries:write") && (
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => handleApproveInquiry(item.inquiry_id)}
                          >
                            Aprobar
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5" className="text-end"><strong>Total:</strong></td>
                    <td className="text-right"><strong>{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalAmount)}</strong></td>                
                    <td colSpan="2"></td>
                  </tr>
                </tfoot>
              </Table>
            </>
          )}
        </>
      )}
      {/* Add New Inquiries */}
      {selectedBudgetId && (
        <>
          <h3 className="mt-4">Agregar Nuevas Solicitudes</h3>
          <Button onClick={handleAddRow} className="mb-2">
            Añadir Fila
          </Button>
          {/* Table for New Items */}
          <Table bordered hover>
            <thead>
              <tr>
                <th>Elemento</th>
                <th>Código</th>
                <th>Descripción</th>
                <th>Cantidad Restante</th>
                <th>Cantidad</th>
                <th>Unidad</th>
                <th>Costo Unitario</th>
                <th>Costo Total</th>
              </tr>
            </thead>
            <tbody>
              {newInquiryItems.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Form.Select
                      value={row.item_id}
                      onChange={(e) => handleRowChange(index, "item_id", e.target.value,true)}
                    >
                      <option value="">-- Seleccionar --</option>
                      {budgetItems.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.description}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                  <td>{row.costCode}</td>
                  <td>{row.description}</td>
                  <td className="text-right">{new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(!isNaN(parseFloat(row.remaining_quantity))? parseFloat(row.remaining_quantity): 0)}</td>                  
                  <td className="text-right">
                    <Form.Control
                      ref={(el) => (quantityRefs.current[index] = { current: el })}
                      type="number"
                      value={row.quantity}
                      onFocus={(e) => e.target.select()} // Select the value when focused
                      onChange={(e) => handleRowChange(index, "quantity", e.target.value)}
                    />
                  </td>
                  <td className="text-right">
                    <Form.Select
                    value={row.unitOfMeasure || ""}
                    onChange={(e) => handleRowChange(index, "unitOfMeasure", e.target.value)}
                    ref={(el) => (quantityRefs.current[index] = { current: el })}
                  >
                    <option value="">-- Unidad --</option>
                    {units.map((unit) => (
                      <option key={unit.unit_name} value={unit.unit_id}>
                        {unit.unit_name}
                      </option>
                    ))}
                  </Form.Select>
                  </td>                  
                  <td className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(row.unitCost)}</td>
                  <td className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(row.total_cost)}</td>
                </tr>
              ))}
            </tbody>           
          </Table>
          <Button variant="success" onClick={handleSubmit}>
            Guardar Solicitudes
          </Button>
        </>        
      )}      
      
      {/* Alert */}
      {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}
    </Container>
  );
};

export default InquiriesPage;
