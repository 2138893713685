import React, { useEffect, useState } from "react";
import { Table, Button, Alert, Container,Spinner } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { hasPermission } from "../context/UserContext";
import {
  getInquiries,
  getPOs,
  getProjects,
  approveInquiry,
  approvePO,
} from "../services/api";

const ApprovalPage = ({ userPrivileges, userId }) => {
  const [projects, setProjects] = useState([]);
  const [pendingInquiries, setPendingInquiries] = useState([]);
  const [pendingPOs, setPendingPOs] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertVariant, setAlertVariant] = useState('info');
  const [loadingData, setLoadingData] = useState(true);

    // Fetch projects on load
    useEffect(() => {
    const fetchProjects = async () => {
    const queryParams = {status:[""]}
        const projectsData = await getProjects();
        setProjects(projectsData);
    };
    fetchProjects();
    }, []);
  // Fetch pending inquiries and POs on load



  useEffect(() => {
    const fetchPendingApprovals = async () => {
      try {
        const inquiriesData = await getInquiries(14,10);
        const POsData = await getPOs(14);
        setPendingInquiries(inquiriesData);
        setPendingPOs(POsData);
      } catch (error) {
        console.error("Error fetching pending approvals:", error);
        setAlertMessage("Error al cargar las solicitudes pendientes.");
        setAlertVariant('danger');
      }
      finally {
        setLoadingData(false);
      }
    };
    fetchPendingApprovals();
  }, []);

  // Handle approval of an Inquiry
  const handleApproveInquiry = async (projectId,inquiryId) => {
    try {
      await approveInquiry(projectId,inquiryId, userId);
      setPendingInquiries((prev) =>
        prev.filter((inquiry) => inquiry.inquiry_id !== inquiryId)
      );
      setAlertMessage("Requisición aprobada exitosamente.");
      setAlertVariant('success');
    } catch (error) {
      console.error("Error approving inquiry:", error);
      setAlertMessage("Error al aprobar la requisición - " + error);
      setAlertVariant('warning');
    }
  };

  // Handle approval of a PO
  const handleApprovePO = async (poId) => {
    try {
      await approvePO(poId, userId);
      setPendingPOs((prev) => prev.filter((po) => po.po_id !== poId));
      setAlertMessage("Orden de compra aprobada exitosamente.");
      setAlertVariant('success');
    } catch (error) {
      console.error("Error approving PO:", error);
      setAlertMessage("Error al aprobar la orden de compra.");
      setAlertVariant('warning');
    }
  };
  
  return (
    <Container className="mt-4">
      <h1>Aprobaciones Pendientes</h1>
      {alertMessage && <Alert variant={alertVariant}>{alertMessage}</Alert>}

      {/* Pending Inquiries */}
      <h3 className="mt-4">Requisiciones Pendientes</h3>
      {pendingInquiries.length > 0 ? (
        <Table className="compressedTable" bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Descripción</th>
              <th>Cantidad</th>
              <th>Costo Unitario</th>
              <th>SubTotal</th>
              <th>Solicitado Por</th>
              <th>Fecha</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {pendingInquiries.map((inquiry) => (
              <tr key={inquiry.inquiry_id}>
                <td data-label="Id">{inquiry.inquiry_id}</td>
                <td data-label="Descripción">{inquiry.description}</td>                
                <td data-label="Cantidad" className="text-right">{new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(inquiry.quantity)} </td>            
                <td data-label="Costo Unitario"className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(inquiry.unitCost)}</td>                                            
                <td data-label="SubTotal" className="text-right">{new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(inquiry.unitCost * inquiry.quantity)}</td>                                                            
                <td data-label="Solicitado por">{inquiry.requestor_name}</td>
                <td data-label="Fecha">{new Date(inquiry.created_at).toLocaleDateString()}</td>
                <td >
                  {inquiry.status != 'Autorizado' && hasPermission(userPrivileges, "inquiries", ["write", "approve"]) && (
                  
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => handleApproveInquiry(inquiry.project_id,inquiry.inquiry_id)}
                    >
                      Aprobar
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No hay requisiciones pendientes.</p>
      )}

      {/* Pending POs */}
      <h3 className="mt-4">Órdenes de Compra Pendientes</h3>
      
      {loadingData ? (
        <Spinner animation="border" />
      ) : (
        <>        
          {pendingPOs.length > 0 ? (
            <Table className="compressedTable" bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Obra</th>
                  <th>Número de Orden</th>
                  <th>Descripción</th>  
                  <th>Total</th>
                  <th>Comprador</th>
                  <th>Fecha</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {pendingPOs.map((po) => (
                  <tr key={po.po_id}>
                    <td data-label="Id">{po.po_id}</td>
                    <td data-label="Obra">{po.project_name}</td>
                    <td data-label="Orden">{po.po_number}</td>
                    <td data-label="Descripción">{po.description}</td>
                    <td data-label="Total" className="text-right">{new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(po.total_amount)} </td>
                    <td data-label="Comprador">{po.buyer}</td>
                    <td data-label="Fecha">{new Date(po.created_at).toLocaleDateString()}</td>
                    <td>
                      {po.status != 'Aprobado' && hasPermission(userPrivileges, "approvals", ["write", "approve"]) && (
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => handleApprovePO(po.po_id)}
                        >
                          Aprobar
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No hay órdenes de compra pendientes.</p>
          )}
      </>
      )}
    </Container>
  );
};

export default ApprovalPage;
