import React, { createContext, useContext, useState } from "react";

// Create the context
const AlertContext = createContext();

// Create a provider component
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ message: null, variant: "info" });

  // Function to show an alert
  const showAlert = (message, variant = "info") => {
    setAlert({ message, variant });
  };

  // Function to clear the alert
  const clearAlert = () => {
    setAlert({ message: null, variant: "info" });
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, clearAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

// Custom hook to use the alert context
export const useAlert = () => {
  return useContext(AlertContext);
};
