import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import { getProjects, updateProject, getUsers, getProjectStatuses } from "../services/api";
import { useNavigate, useParams } from "react-router-dom";

const EditProjectPage = ({userId}) => {
  const [projectData, setProjectData] = useState({
    name: "",
    description: "",
    project_manager: "",
    start_date: "",
    end_date: "",
    status_id: "",
  });
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [projects,setProjects] =useState([]);

  
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedStatusId, setSelectedStatusId] = useState("");
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();
  const { projectId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [projectsList,userList, statusList] = await Promise.all([        
          getProjects({all:1}),
          getUsers(),
          getProjectStatuses(),
        ]);

        setProjects(projectsList);
        setUsers(userList);
        setStatuses(statusList);        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;    
    setProjectData({ ...projectData, [name]: value });
  };

  const handleStatusChange =async(e) => {
    setSelectedStatusId(e.target.value);
  }

  const handleProjectChange =async(e) => {
    setSelectedProjectId(e.target.value);


    const projectInfo = projects.find((project) => project.id === parseInt(e.target.value));
    const statusMap={'Planeado':1,'En progreso':2,'Terminado':3,'Detenido':4,'Cancelado':5,'Archivado':6}
        // Update projectData with the selected project's information
    setProjectData({
        name: projectInfo.name,
        description: projectInfo.description || "",
        project_manager: projectInfo.pm_name,
        status_id: projectInfo.status,
        start_date: projectInfo.start_date,
        end_date: projectInfo.end_date || "",
    });
    setSelectedStatusId(statusMap[projectInfo.status]);

    //const projectResult = await getProjects(parseInt(e.target.value));
    //setProjectData(projectResult);
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateProject({...projectData,status_id:selectedStatusId,project_id:parseInt(selectedProjectId),user_id:userId});
      setAlert("Proyecto actualizado exitosamente.");
      //setTimeout(() => navigate("/projects"), 1000);
    } catch (error) {
      console.error("Error updating project:", error);
      setAlert("Error al actualizar el proyecto.");
    }
  };

  return (
    <Container className="mt-4">
      <h2>Editar Proyecto</h2>
      {alert && <Alert variant="info">{alert}</Alert>}
      <Form onSubmit={handleSubmit}>
        {/* Project Selector */}
        <Form.Group className="project-select">
        <Form.Label>Selecciona un proyecto:</Form.Label>
        <Form.Control as="select" onChange={handleProjectChange} value={selectedProjectId || ""} style={{ width: "300px" }}>
            <option value="">-- Selecciona un proyecto --</option>
            {projects.map((project) => (
            <option key={project.id} value={project.id}>
                {project.name}
            </option>
            ))}
        </Form.Control>
        </Form.Group>
        {selectedProjectId && (
            <>    
                <Form.Group className="mb-3">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={projectData.name}
                    onChange={handleChange}
                    required
                />
                </Form.Group>
        
                <Form.Group className="mb-3">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                    as="textarea"
                    name="description"
                    value={projectData.description}
                    onChange={handleChange}
                    required
                />
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Label>Administrador del Proyecto</Form.Label>
                <Form.Select
                    name="project_manager"
                    value={projectData.project_manager}
                    onChange={handleChange}
                    required
                >
                    <option value="">-- Seleccionar Usuario --</option>
                    {users.map((user) => (
                    <option key={user.user_id} value={user.user_name}>
                        {user.user_name}
                    </option>
                    ))}
                </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Label>Estatus</Form.Label>
                <Form.Select
                    name="status_id"
                    value={selectedStatusId}
                    onChange={handleStatusChange}
                    required
                >
                    <option value="">-- Seleccionar Estatus --</option>
                    {statuses.map((status) => (
                    <option key={status.status_id} value={status.status_id}>
                        {status.status_name}
                    </option>
                    ))}
                </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Label>Fecha de Inicio</Form.Label>
                <Form.Control
                    type="date"
                    name="start_date"
                    value={projectData.start_date}
                    onChange={handleChange}
                    required
                />
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Label>Fecha de Fin</Form.Label>
                <Form.Control
                    type="date"
                    name="end_date"
                    value={projectData.end_date}
                    onChange={handleChange}
                />
                </Form.Group>

                <Button type="submit" variant="primary">
                Actualizar Proyecto
                </Button>
            </>
        )}
      </Form>
    </Container>
  );
};

export default EditProjectPage;
