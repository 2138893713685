import React, { useEffect, useState,useMemo } from "react";
import { useParams } from "react-router-dom";
import { Table, Button, Form, Container, Alert } from "react-bootstrap";
import { getCashflows, getPettyCashContainers,getProjects, getUsers,deleteCashflow, getVendors,deletePettyCash} from "../services/api"; // Placeholder for API
import { useAlert } from "../components/Alerts";
import { pettycashStatusTypeMap} from "../types/PettycashModuleTypes";
import { statusTypeMap} from "../types/CashflowModuleTypes";

const ReportsPage = ({userId}) => {
  const { reportType } = useParams();
  const [reportData, setReportData] = useState([]);
  const [projects, setProjects] = useState([]);

  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [users, setUsers] = useState([]); // List of users for the dropdown
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  
  const { alert, showAlert, clearAlert } = useAlert();
  
  const [filteredFields,setFilteredFields] = useState(["project_id", "category_id","company_id","project_manager_id"]);

  const statusMapping = {
    requested: "Solicitado",
    approved: "Aprobado",
    rejected: "Rechazado",
    pending: "Pendiente",
    valid: "Válido",
  };
  

  const getStatusList = (type) => {
    return type === "pettyCash"
      ? ["requested", "approved", "rejected"]
      : ["pending", "valid", "rejected"];
  };

  const [statusList, setStatusList] = useState(getStatusList(reportType));


  const [filters, setFilters] = useState({
    dateRange: "thisMonth",
    startDate: "",
    endDate: "",
    name: "",
    project_id: null,
    category_id: null,
    status: getStatusList(reportType), // Initialize with correct status list
  });

  
  useEffect(() => {
    fetchReport();
  }, [filters,reportType]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      status: getStatusList(reportType),
    }));
    if (reportType === "cashflow") setFilteredFields((prev) =>([...prev,"petty_cash_id"]));
  }, [reportType]);
  
  const calculateDateRange = (range) => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const startOfYear = new Date(today.getFullYear(), 0, 1);

    switch (range) {
      case "thisMonth":
        return { startDate: startOfMonth.toISOString().split("T")[0], endDate: endOfMonth.toISOString().split("T")[0] };
      case "lastMonth":
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        return { startDate: lastMonthStart.toISOString().split("T")[0], endDate: lastMonthEnd.toISOString().split("T")[0] };
      case "thisYear":
        return { startDate: startOfYear.toISOString().split("T")[0], endDate: today.toISOString().split("T")[0] };
      case "custom":
      default:
        return { startDate: filters.startDate, endDate: filters.endDate };
    }
  };

  const fetchReport = async () => {
    try {
      const dateRange = calculateDateRange(filters.dateRange);
      const appliedFilters = {
        ...filters,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        //status: ['pending','valid','rejected'],
      };

      let data;

      const [projectsData, userList] = await Promise.all([
        getProjects(),
        getUsers(),
      
      ]);
      
      setUsers(userList || []);
      setProjects(projectsData || []);
      
      if (reportType === "cashflow") {        
        const cashflowFilter = {...appliedFilters,excludePettyCash:1};
        data = await getCashflows(cashflowFilter);
        
      } else if (reportType === "projects") {
        data = projectsData;
      }
      else if (reportType === "pettyCash")
      {
        const pettyCashFilter = {...appliedFilters,petty_cash:1};
        data = await getPettyCashContainers(pettyCashFilter);
      }
      else if (reportType === "vendors")
      {
        
        data = await getVendors(appliedFilters);
      }
      else if(reportType === "customers")
      {
        data = await getVendors(appliedFilters);
      }
  
      // Add a new common field to each item in the data
      const updatedData = data.map((item) => ({
        ...item,
        Acción: "defaultValue", // Replace "defaultValue" with your desired default value
      }));
  
      
      setReportData(updatedData);
      showAlert(null);
    } catch (error) {
      console.error("Error fetching report:", error);
      setReportData(null);
      showAlert("Error al obtener el reporte.","danger");
    }
  };

  const handleDelete = async (item) =>{
    try {
      let response;
      var itemToDelete;
      if (reportType === "cashflow" ){                 
        const queryParams ={
          cf_id: item.cf_id,
          user_id : userId
        }
        itemToDelete = item.cf_id;
        response = await deleteCashflow(queryParams);         
      }
      else if(reportType === "pettyCash"){
        const queryParams ={
          request_id: item.request_id,
          user_id : userId
        }
        itemToDelete = item.request_id;
        response = await deletePettyCash(queryParams);
        
      }

      if(response.status === 200)
      { 
        setReportData((prev) => prev.filter((cf) => (reportType === "cashflow"?cf.cf_id:cf.request_id) !== itemToDelete));
        showAlert("Transacción eliminada correctamente.","success");
        

      } else {
        // Handle unsuccessful response
        showAlert("No se pudo eliminar la transacción. Intenta de nuevo.","danger");
        
      }          

    } catch (error) {
      showAlert("Error al eliminar la transacción.","danger");
      
    }
  };


  const handleStatusChange  = (e) => 
    {
      setSelectedStatus(e.target.value);
      handleFilterChange(e)
    };

  const handleProjectChange = (e) => 
  {
    setSelectedProjectId(e.target.value);
    handleFilterChange(e)
  };

  const handleUserChange = (e) => 
    {
      setSelectedUser(e.target.value);
      handleFilterChange(e)
    };

  
    const handleFilterChange = (e) => {
      const { name, value } = e.target;
      setFilters((prev) => ({
        ...prev,
        [name]: name === "status" ? [value] : value, // Handle array for status
      }));
    };

  //const status = [{id:1,name:'pending',display:'pendiente'},{id:2,name:'valid',display:'valido'},{id:3,name:'rejected',display:'rechazado'}];  
  
  const fieldTranslations = {
    cf_id: "ID",
    request_id: "ID",
    vendor_id: "ID",
    invoice_id: "Factura",
    start_date: "Fecha Inicio",
    end_date:"Fecha Fin",
    pm_name:"Nombre PM",
    name: "Nombre",
    cf_date: "Fecha",
    project_name: "Obra",
    category_name: "Departamento",
    source_name: "Cuenta",
    concept: "Concepto",
    description:"Descripción",
    transaction_type: "Tipo",
    operation_type: "Operación",
    totalAmount: "Monto",
    cfdi_id: "CFDI",
    notes: "Notas",
    status: "Estatus",
    amount: "Monto",
    requestedBy: "Solicitante",
    approvedBy: "Aprobación",
    reason:"Justificación",
    created_at:"Fecha",
    address:"Dirección",
    phone_number:"Teléfono",
    contact_name:"Nombre Contacto",
    vendor_name:"Proveedor",
    vendor_number:"Numero",
    linked_invoices:"Facturas",
    currency_id:"Moneda",
    updated_at:"Actualizado"
  };

  const usersMap = users.reduce((dict, user) => {
    dict[user.user_id] = user.user_name;
    return dict;
  }, {});
  const dataFieldtranslation = useMemo(() => {

    var statusRendered;
    switch(reportType){
      case "pettyCash":
        statusRendered = pettycashStatusTypeMap;
      break;
      case "cashflow":
        statusRendered =  statusTypeMap;
        break;
      case "projects":
        statusRendered =  {"Planeado":"Planeado"};
    }
    

    return {
      approvedBy: usersMap,
      requestedBy: usersMap,
      status: statusRendered,
    };
  }, [usersMap, reportType, pettycashStatusTypeMap, statusTypeMap]);

  const fieldDtDict = {totalAmount:'currency',amount:'currency',created_at:'date',updated_at:'date'};

  const renderTableHeaders = () => {
    if (!reportData.length) return null;
    return Object.keys(reportData[0])
      .filter((key) => !filteredFields.includes(key))
      .map((key) => <th key={key}>{fieldTranslations[key] || key}</th>);
  };

  const renderTableRows = () => {
    const filteredKeys = Object.keys(reportData[0]).filter(
      (key) => !filteredFields.includes(key)
    );
  
    return reportData.map((row, index) => (
      <tr key={index}>
        {filteredKeys.map((key, idx) => {
         
          const  cellValue = dataFieldtranslation[key]? dataFieldtranslation[key][row[key]]:row[key]                    
          let isCurrency = false;  
          var formattedValue;
          if (key in fieldDtDict && fieldDtDict[key] === 'currency'){
            isCurrency = true;
          formattedValue = (
              // Format currency fields
              new Intl.NumberFormat("es-MX", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(cellValue)
            );
          }
          else if (key in fieldDtDict && fieldDtDict[key] === 'date'){
            const date = new Date(cellValue.replace(" ", "T"));
            formattedValue = date.toLocaleString("es-MX",{
              year: "numeric",
              month: "2-digit",
              day: "2-digit",            
              hour12: false,
            }) ;
          }
          else {
            formattedValue = cellValue;
          }
          
          return (
            <td data-label={fieldTranslations[key] || key} key={idx} className={isCurrency ? "text-right" : ""}>
            {key === "Acción" ? (
              // Render the Edit button for commonField
              <button
                className="btn btn-sm btn-secondary"
                variant="danger"
                onClick={() => handleDelete(reportData[index])}                
              >
                Eliminar
              </button>
            ) : formattedValue}
          </td>
          );
        })}
      </tr>
    ));
  };

  const reportTitles = {
    projects: "Reporte de Proyectos",
    cashflow: "Reporte de Flujo de Efectivo",
    vendors: "Reporte de Proveedores",
    pettyCash: "Reporte de Caja Chica",
    customers: "Reporte de Clientes",
    budgets: "Reporte de Presupuestos",
  };

  return (
    <Container className="mt-4">
      <h2>{reportTitles[reportType] || "Reporte"}</h2>
      
      {/* Filters */}
      <Form className="mb-3">
        <Form.Group>
          <Form.Label>Filtrar por Fecha</Form.Label>
          <Form.Select name="dateRange" onChange={handleFilterChange}>
            <option value="thisMonth">Este Mes</option>
            <option value="lastMonth">Mes Pasado</option>
            <option value="thisYear">Este Año</option>
            <option value="custom">Personalizado</option>
          </Form.Select>
        </Form.Group>
        {filters.dateRange === "custom" && (
          <>
            <Form.Group>
              <Form.Label>Fecha de Inicio</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Fecha Final</Form.Label>
              <Form.Control
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
              />
            </Form.Group>
          </>
        )}
                                
        <Form.Group className="mb-3">
          <Form.Label>Filtrar por Obra</Form.Label>                      
          <Form.Select name = "project_id" value={selectedProjectId} onChange={handleProjectChange}>
            <option value="">-- Seleccionar Proyecto --</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>{project.name}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Filtrar por solicitante</Form.Label>                      
          <Form.Select name = "requestedBy" value={selectedUser} onChange={handleUserChange}>
            <option value="">-- Seleccionar Solicitante --</option>
            {users.map((user) => (
              <option key={user.user_id} value={user.user_id}>{user.user_name}</option>
            ))}
          </Form.Select>
        </Form.Group>
              
        <Form.Group className="mb-3">
          <Form.Label>Filtrar por Estatus</Form.Label>                      
          <Form.Select name = "status" value={selectedStatus} onChange={handleStatusChange}>
            <option value="">-- Seleccionar Estatus --</option>
            {statusList.map((status) => (
            <option key={status} value={status}>
              {statusMapping[status] || status} {/* Fallback to raw status if mapping is missing */}
            </option>
          ))}
          </Form.Select>
        </Form.Group>
        <Button variant="primary" className="mt-2" onClick={fetchReport}>
          Generar Reporte
        </Button>
      </Form>


      {/* Alert */}
      {alert.message && (
        <Alert variant={alert.variant} onClose={clearAlert} dismissible>
          {alert.message}
        </Alert>
      )}

      {/* Report Table */}
      {reportData?.length > 0 ? (
        <Table striped bordered hover id={`${reportType}-table`}>
          <thead>
            <tr>{renderTableHeaders()}</tr>            
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </Table>
      ) : (
        <p>No hay datos disponibles para este reporte.</p>
      )}
    </Container>
  );
};

export default ReportsPage;
