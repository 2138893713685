import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Container, Alert } from "react-bootstrap";
import { getCatalog, addCatalogItem, updateCatalogItem, deleteCatalogItem } from "../services/api";

const CatalogPage = ({ catalogType }) => {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [alert, setAlert] = useState(null);

  const [formData, setFormData] = useState({
    vendor_number: "",
    vendor_name: "",
    contact_name: "",
    phone_number: "",
    email: "",
    address: "",
  });

  // Fetch the catalog data
  useEffect(() => {
    const fetchCatalog = async () => {
      try {
        const data = await getCatalog(catalogType);
        setItems(data);
      } catch (error) {
        console.error("Error fetching catalog:", error);
      }
    };
    fetchCatalog();
  }, [catalogType]);

  // Open modal for Add/Edit
  const handleShowModal = (item = null) => {
    setSelectedItem(item);
    setFormData(
      item || {
        vendor_number: "",
        vendor_name: "",
        contact_name: "",
        phone_number: "",
        email: "",
        address: "",
      }
    );
    setShowModal(true);
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Add or Edit Item
  const handleSubmit = async () => {
    try {
      if (selectedItem) {
        await updateCatalogItem(catalogType, selectedItem.vendor_id, formData);
        setAlert("Elemento actualizado con éxito.");
      } else {
        await addCatalogItem(catalogType, formData);
        setAlert("Elemento agregado con éxito.");
      }
      setShowModal(false);
      setTimeout(() => setAlert(null), 3000);
    } catch (error) {
      console.error("Error saving item:", error);
      setAlert("Error al guardar el elemento.");
    }
  };

  // Delete Item
  const handleDelete = async (id) => {
    try {
      await deleteCatalogItem(catalogType, id);
      setAlert("Elemento eliminado con éxito.");
      setItems(items.filter((item) => item.vendor_id !== id));
    } catch (error) {
      console.error("Error deleting item:", error);
      setAlert("Error al eliminar el elemento.");
    }
  };

  return (
    <Container className="mt-4">
      <h2>{catalogType === "vendors" ? "Proveedores" : "Clientes"}</h2>
      {alert && <Alert variant="success">{alert}</Alert>}
      <Button variant="primary" onClick={() => handleShowModal()}>
        Agregar Nuevo
      </Button>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>ID</th>
            <th>Número</th>
            <th>Nombre</th>
            <th>Contacto</th>
            <th>Teléfono</th>
            <th>Email</th>
            <th>Dirección</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.vendor_id}>
              <td>{item.vendor_id}</td>
              <td>{item.vendor_number}</td>
              <td>{item.vendor_name}</td>
              <td>{item.contact_name}</td>
              <td>{item.phone_number}</td>
              <td>{item.email}</td>
              <td>{item.address}</td>
              <td>
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => handleShowModal(item)}
                  className="me-2"
                >
                  Editar
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDelete(item.vendor_id)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Add/Edit Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedItem ? "Editar Elemento" : "Agregar Nuevo Elemento"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Número</Form.Label>
              <Form.Control
                name="vendor_number"
                value={formData.vendor_number}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                name="vendor_name"
                value={formData.vendor_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Contacto</Form.Label>
              <Form.Control
                name="contact_name"
                value={formData.contact_name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                as="textarea"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CatalogPage;
