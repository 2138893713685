import React, { useState } from "react";
import { Modal, Button, Table, Form, Row, Col , Spinner} from "react-bootstrap";

const InvoiceSelectionModal = ({ show, onHide, invoices, onSelect, onSearch,isLoading }) => {
  const defaultFilters = {
    nombre_emisor: "",
    fecha_emision: "",
    conceptos: "",
    total: "",
  };
  const [filters, setFilters] = useState(defaultFilters);

  const handleRowClick = (invoice) => {
    onSelect({ folio: invoice.folio, name: invoice.nombre_emisor ,uuid: invoice.invoice_id, amount:invoice.total,fecha_emision:invoice.fecha_emision}); // Return selected folio and name  
    onHide(); // Close modal
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    onSearch(filters); // Trigger the parent component's search function
  };


  const handleModalExited = () => {
    setFilters(defaultFilters); // Reset filters when the modal is completely hidden
  };

  return (
    <Modal show={show} onHide={onHide}  onExited={handleModalExited}  centered>
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar Factura</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-2">
            <Col>
              <Form.Control
                type="date"table
                name="start_date"
                value={filters.start_date}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                placeholder="Nombre Emisor"
                name="nombre_emisor"
                value={filters.nombre_emisor}
                onChange={handleInputChange}
              />
            </Col>
            
            <Row className="mb-3"></Row>
            <Col>
              <Form.Control
                type="text"
                placeholder="Conceptos"
                name="conceptos"
                value={filters.conceptos}
                onChange={handleInputChange}
              />
            </Col>
            <Col>
              <Form.Control
                type="number"
                placeholder="Total"
                name="total"
                value={filters.total}
                onChange={handleInputChange}
              />
            </Col>
            <Col xs="auto">
              <Button variant="primary" disabled = {isLoading}onClick={handleSearch}>
              {isLoading ? (
                    <>
                    <Spinner as="span" animation="border"  size="sm"  role="status"   aria-hidden="true"
                    />{" "}
                    Cargando...
                    </>
                ) : "Buscar"}
              </Button>
            </Col>
          </Row>
        </Form>
        <div class ="seach-box-container">
            <Table striped bordered hover>
            <thead  class="seach-box-headers">
                <tr>
                <th>Fecha Emisión</th>
                <th>Folio</th>
                <th>Nombre Emisor</th>
                <th>Conceptos</th>
                <th>Total</th>
                </tr>
            </thead>
            <tbody>
                {invoices.map((invoice) => (
                <tr
                    key={invoice.invoice_id}
                    onClick={() => handleRowClick(invoice)}
                    style={{ cursor: "pointer" }}
                >
                    <td>{invoice.fecha_emision}</td>
                    <td>{invoice.folio}</td>
                    <td>{invoice.nombre_emisor}</td>
                    <td>{invoice.conceptos}</td>
                    <td>
                    {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                    }).format(invoice.total)}
                    </td>
                </tr>
                ))}
            </tbody>
            </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceSelectionModal;
