import React, { useState, useEffect, useCallback } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Projects from './pages/Projects';
import AddProjectPage from './pages/AddProjectPage';
import Inquiries from "./pages/Inquiries";
import PORequestPage from "./pages/PORequestPage";
import LoginPage from "./pages/LoginPage";
import ImportPage from "./pages/ImportPage";
import CatalogPage from "./pages/CatalogPage";
import ReportsPage from "./pages/ReportsPage";
import ApprovalPage from "./pages/ApprovalPage";
import SystemConfigPage from "./pages/SystemConfigPage";
import CashflowPage from "./pages/cashflowPage";
import CashflowReportsPage from "./pages/cashFlowReportsPage";
import NotificationsInbox from "./components/NotificationsInbox";
import IncomingCashflowPage from "./pages/IncomingCashflowPage";
import DeleteProjectPage from "./pages/DeleteProjectPage";
import BankTransactionViewPage from "./pages/BankTransactionViewPage";
import BankConciliationPage from "./pages/BankConciliationPage";
import PatternRulesPage from "./pages/PatternRulesPage";
import { NotificationsProvider } from "./context/NotificationsContext";
import { getUserPrivileges } from "./services/api";
import EditProjectPage from "./pages/EditProjectPage";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userPrivileges, setUserPrivileges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Memoized function to fetch user privileges
  const fetchUserPrivileges = useCallback(async (id) => {
    try {
      const privileges = await getUserPrivileges(id);
      const transformedPrivileges = privileges.map(
        (priv) => `${priv.module_name}:${priv.permission_level}`
      );
      setUserPrivileges(transformedPrivileges);
    } catch (error) {
      console.error("Error fetching privileges:", error);
    }
  }, []);

  // Load login state and fetch user privileges
  useEffect(() => {
    const userLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const storedUserId = localStorage.getItem("userId");
    const storedRoleId = parseInt(localStorage.getItem("roleId"));
    const storedName = localStorage.getItem("userName");

    if (userLoggedIn && storedUserId) {
      setIsLoggedIn(true);
      setUserId(storedUserId);
      setRoleId(storedRoleId);
      setUserName(storedName);
      fetchUserPrivileges(storedUserId); // Fetch privileges on initial load
    }
    setIsLoading(false); // Stop loading state
  }, [fetchUserPrivileges]);

  const handleLogin = async (id, name, roleId) => {
    setIsLoggedIn(true);
    setUserId(id);
    setRoleId(roleId);
    setUserName(name);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("userId", id);
    localStorage.setItem("roleId", roleId);
    localStorage.setItem("userName", name);
    try {
      await fetchUserPrivileges(id); // Fetch privileges after login
    } catch (error) {
      console.error("Failed to fetch user privileges:", error);
    }
    navigate("/dashboard");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserId(null);
    setRoleId(null);
    setUserName(null);
    setUserPrivileges([]);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userId");
    localStorage.removeItem("roleId");
    navigate("/login");
  };

  if (isLoading) {
    // Render a loading spinner while checking login state
    return <div className="text-center mt-5"><h3>Cargando...</h3></div>;
  }

  return (
    <NotificationsProvider userId={userId}>
      <div className="container-fluid">
        <Navbar
          isLoggedIn={isLoggedIn}
          userPrivileges={userPrivileges}
          userId={userId}
          fetchUserPrivileges={fetchUserPrivileges}
        />
        <div className="row">
          <div className="col-12">
            <Routes>
              <Route path="/" />
              <Route path="/login" element={<LoginPage handleLogin={handleLogin} />} />
              <Route path="/dashboard" element={isLoggedIn ? <Dashboard handleLogout={handleLogout} /> : <Navigate to="/login" />} />
              <Route path="/users" element={isLoggedIn ? <Users userId={userId} /> : <Navigate to="/login" />} />
              <Route path="/projects" element={isLoggedIn ? <Projects /> : <Navigate to="/login" />} />
              <Route path="/projects/add" element={<AddProjectPage  userId={userId}/>} />
              <Route path="/projects/edit" element={<EditProjectPage  userId={userId}/>} />
              <Route path="/projects/delete" element={<DeleteProjectPage userId={userId} />} />
              <Route path="/inquiries" element={isLoggedIn ? <Inquiries userId={userId} userPrivileges={userPrivileges} /> : <Navigate to="/login" />} />
              <Route path="/approvals" element={isLoggedIn ? <ApprovalPage userPrivileges={userPrivileges} userId={userId} /> : <Navigate to="/login" />} />
              <Route path="/pos" element={isLoggedIn ? <PORequestPage userId={userId} /> : <Navigate to="/login" />} />
              <Route path="/notifications" element={isLoggedIn ? <NotificationsInbox userId={userId} /> : <Navigate to="/login" />} />
              <Route path="/catalogs/vendors" element={isLoggedIn ? <CatalogPage catalogType="vendor" /> : <Navigate to="/login" />} />
              <Route path="/catalogs/customers" element={isLoggedIn ? <CatalogPage catalogType="customers" /> : <Navigate to="/login" />} />
              <Route path="/reports/:reportType" element={isLoggedIn ? <ReportsPage userId ={userId}/> : <Navigate to="/login" />} />
              <Route path="/cashflow" element={isLoggedIn ? <CashflowPage userId={userId} userName={userName}  roleId={roleId}/> : <Navigate to="/login" />} />
              <Route path="/cashflow-reports" element={isLoggedIn ? <CashflowReportsPage /> : <Navigate to="/login" />} />
              <Route path="/bank-statement" element={isLoggedIn ? <BankTransactionViewPage userId={userId} /> : <Navigate to="/login" />} />
              <Route path="/bank-reconciliation" element={isLoggedIn ? <BankConciliationPage userId={userId} /> : <Navigate to="/login" />} />
              <Route path="/pattern-rules" element={isLoggedIn ? <PatternRulesPage userId={userId} /> : <Navigate to="/login" />} />
              <Route path="/invoice-inbox" element={isLoggedIn ? <IncomingCashflowPage userId={userId} /> : <Navigate to="/login" />} />
              <Route path="/system/config" element={isLoggedIn ? <SystemConfigPage /> : <Navigate to="/login" />} />
              <Route path="/system/import" element={isLoggedIn ? <ImportPage userId={userId} userPrivileges={userPrivileges} /> : <Navigate to="/login" />} />
            </Routes>
          </div>
        </div>
      </div>
    </NotificationsProvider>
  );
};

export default App;
