import React, { useState, useEffect } from "react";
import { Container, Form, Button, Alert, Spinner } from "react-bootstrap";
import { uploadCsvFile, getProjects, getBanks } from "../services/api"; // Import API calls

const ImportPage = ({ userId, userPrivileges }) => {
  const [projects, setProjects] = useState([]); // List of projects
  const [banks, setBanks] = useState([]); // List of projects
  
  const [selectedProjectId, setSelectedProjectId] = useState(""); // Selected project ID
  const [selectedType, setSelectedType] = useState("budget"); // Default import type
  const [fileType,setFileType] = useState("excel");
  const [selectedBankId, setSelectedBankId] = useState(""); // Selected project ID
  
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({ success: null, message: "" });

  // Fetch list of projects on component mount
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectsData = await getProjects();
        const banksData = await getBanks();
        setProjects(projectsData);
        setBanks (banksData);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setUploadStatus({
          success: false,
          message: "Error al cargar los proyectos.",
        });
      }
    };
    fetchProjects();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileTypeChange = (e) => {    
    setFileType(e.target.value);
  };
  const handleImportTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleProjectChange = (e) => {
    setSelectedProjectId(e.target.value);
  };

  const handleBanksChange = (e) => {
    setSelectedBankId(e.target.value);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file || !selectedProjectId && (selectedType != "bank-transaction" && selectedType != "invoices") || !selectedBankId && (selectedType != "projects" && selectedType != "invoices")) {
      setUploadStatus({
        success: false,
        message: "Por favor selecciona un proyecto y un archivo CSV.",
      });
      return;
    }

    try {
      setIsLoading(true); 
      const formData = new FormData();
      formData.append("file", file);
      formData.append("importType", selectedType);
      if (selectedBankId) formData.append("bank_id", selectedBankId);      
      if (selectedProjectId) formData.append("project_id", selectedProjectId); // Include project ID
      formData.append("user_id", userId); // Include user ID
      formData.append("fileType", fileType); // Include user ID

      // Call the backend API to process the CSV file
      const response = await uploadCsvFile(formData);

      setUploadStatus({ success: true, message: response.message });
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadStatus({
        success: false,
        message: "Error al importar el archivo.",
      });  
    } finally {
    setIsLoading(false); // Stop loading
  }
  };

  return (
    <Container className="mt-4">
      <h2>Importar Datos desde CSV</h2>
      <Form onSubmit={handleSubmit} className="mt-3">       

        {/* Select Import Type */}
        <Form.Group className="mb-3">
          <Form.Label>Seleccionar Tipo de Importación</Form.Label>
          <Form.Select value={selectedType} onChange={handleImportTypeChange}>
            <option value="budget">Presupuestos</option>
            <option value="inquiries">Requisiciones</option>
            <option value="invoices">XMLs SAT</option>
            <option value="projects">Proyectos</option>
            <option value="bank-transaction">Estado de Cuenta Bancario</option>
          </Form.Select>
        </Form.Group>

        {/* Select Project */}
        {selectedType == "projects" && (
        <Form.Group className="mb-3">
          <Form.Label>Seleccionar Proyecto</Form.Label>
          <Form.Select value={selectedProjectId} onChange={handleProjectChange}>
            <option value="">-- Seleccionar Proyecto --</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>)
        }

      {selectedType == "bank-transaction" && (
        <Form.Group className="mb-3">
          <Form.Label>Cuenta Bancaria</Form.Label>
          <Form.Select value={selectedBankId} onChange={handleBanksChange}>
            <option value="">-- Seleccionar Cuenta --</option>
            {banks.map((banks) => (
              <option key={banks.source_id} value={banks.source_id}>
                {banks.source_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>)
        }
        <Form.Group>
          <Form.Check
            inline
            type="radio"
            id="format-Excel"
            name="format_type"
            label="Excel"
            value='excel'
            checked={fileType === "excel"}            
            onChange = {handleFileTypeChange}
          />   
          <Form.Check
            inline
            type="radio"
            id="format-text"
            name="format_type"
            label="Texto"
            value='txt'
            checked={fileType === "txt"}        
            onChange = {handleFileTypeChange}
          />   
        </Form.Group>
        {/* File Input */}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Seleccionar Archivo</Form.Label>
          <Form.Control type="file" accept={(fileType === 'excel')?".xlsx":".txt"} onChange={handleFileChange} />
        </Form.Group>
        
        {/* Submit Button */}
        <Button variant="primary" type="submit">
          {isLoading ? (
          <>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            Procesando...
          </>
        ) : (
          "Importar"
        )}
        </Button>
      </Form>

      {/* Upload Status */}
      {uploadStatus.message && (
        <Alert variant={uploadStatus.success ? "success" : "danger"} className="mt-3">
          {uploadStatus.message}
        </Alert>
      )}
    </Container>
  );
};

export default ImportPage;
