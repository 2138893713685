export const transactionTypeMap = {
  cash: "efectivo",
  fiscal: "fiscal",
};

export  const operationTypeMap = {
    expense: "gasto",
    income: "ingreso",
  };
  
export const statusTypeMap = {
  pending: "pendiente",
  valid: "aceptado",
  rejected: "rechazado"
}